import React, { useState } from "react";
import { Button, message, Steps, theme } from "antd";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import { useTranslation } from "react-i18next";
import Imageone from "../../../images/step1.png"
import Imagetwo from "../../../images/step2.png"
import Imagethree from "../../../images/step3.png"
import Imagefour from "../../../images/step4.png"

const Process = () => {
  const { t } = useTranslation();
  const steps = [
    {
      title: t("Step-1"),
      content: <Step1 Image1={Imageone}/>,
    },
    {
      title: t("Step-2"),
      content: <Step2 Image2={Imagetwo}/>,
    },
    {
      title: t("Step-3"),
      content: <Step3 Image3={Imagethree}/>,
    },
    {
      title: t("Step-4"),
      content: <Step4 Image4={Imagefour}/>,
    },
  ];
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };
  return (
    <>
     <div className="hidden">
        <img src={Imageone} alt="Step 1" />
        <img src={Imagetwo} alt="Step 2" />
        <img src={Imagethree} alt="Step 3" />
        <img src={Imagefour} alt="Step 4" />
      </div>
      <div className="flex lg:p-8 lg:py-8 lg:space-y-4 bg-white rounded-md lg:mx-36 mx-2 p-3 flex-col ">
        <div className="font-medium space-y-4">
          <p className="text-3xl">{t("Do you want something done?")}</p>
          <p className="text-xl">
            {t("It just a 4-Step Simple Process on Wory")}
          </p>
        </div>
        <div>
          <Steps current={current} items={items} />
          <div className="bg-[#001529d9]" style={contentStyle}>
            {steps[current].content}
          </div>
          <div
            style={{
              marginTop: 24,
            }}
          >
            {current > 0 && (
              <Button
                type="primary"
                style={{
                  backgroundColor: "Highlight",

                  margin: "0 8px",
                }}
                onClick={() => prev()}
              >
                {t("Previous")}
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button
                style={{ backgroundColor: "Highlight" }}
                type="primary"
                onClick={() => message.success("That's It!! You are Done")}
              >
                {t("Done")}
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button
                style={{ backgroundColor: "Highlight" }}
                type="primary"
                onClick={() => next()}
              >
                {t("Next")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Process;

import React, { useContext } from "react";
import { Button, Space, Table, Tag } from "antd";
import { Link, useParams } from "react-router-dom";
import ChatButton from "../Chat/ChatButton";
import Column from "antd/es/table/Column";
import UserDetails from "./Singleuser";
import { SupportContext } from "../../Context/AdminContext";

const Allusers = () => {
  const { allUsers, allTags } = useContext(SupportContext);
  const { id } = useParams();

 

  const datas = allTags.map((data) => data._id);
  const findtags = allUsers
    .flatMap((user) => user.tagsWorkingOn)
    .filter((d) => datas.includes(d));

  const filteredTags = allTags.filter((tag) => findtags.includes(tag._id));
  console.log(filteredTags, "filtered tags");

  if (id) {
    return <UserDetails id={id} />;
  }

  const getRandomColor = () => {
    const colors = ["blue", "red", "green", "orange", "purple", "cyan"];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };
  return (
    <>
      <div
        className="lg:my-6 lg:mx-4 lg:p-6 my-5 mx-2 rounded-xl p-2 md:my-4 md:mx-2 md:p-4"
        style={{
          minHeight: "100vh",
          background: "",
        }}
      >
        <Table dataSource={allUsers} className="overflow-y-auto">
          <Column title="Name" dataIndex="name" key="name" />
          <Column title="Email" dataIndex="email" key="email" />
          <Column
            title="Tags"
            dataIndex="tagsWorkingOn"
            key="tagsWorkingOn"
            render={(tagsWorkingOn) => (
              <>
                {tagsWorkingOn.length > 0 ? (
                  tagsWorkingOn.map((tagId) => {
                    const tag = allTags.find((tag) => tag._id === tagId);
                    const tagColor = getRandomColor();
                    return (
                      <Tag color={tagColor} key={tagId} className="p-2">
                        {tag ? tag.name : "Unknown Tag"}
                      </Tag>
                    );
                  })
                ) : (
                  <span>No tag added yet</span>
                )}
              </>
            )}
          />

          <Column
            title="Action"
            key="action"
            render={(_, record) => (
              <Space size="middle">
                <Link to={`/support/user/${record._id}`}>
                  <Button>View Details</Button>
                </Link>
              </Space>
            )}
          />
        </Table>
        <ChatButton />
      </div>
    </>
  );
};

export default Allusers;

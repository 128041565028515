
import React, { useContext } from 'react';
import {  Table, Tag } from 'antd';
import { SupportContext } from '../../Context/AdminContext';
import { MdOutlineAccessTime } from "react-icons/md";

const Tags = () => {
  const { allTags } = useContext(SupportContext);


  const columns = [
  
    {
      title: 'Tags',
      dataIndex: 'name',
      key: 'name',
      render: (text) =>  <Tag
      color='cyan' className='p-2'
    >
      <div className='flex'><p>  {text}</p>
    
      </div>
    
    </Tag>,
    },
    
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'created',
      render: (createdAt) => {
        const dateObject = new Date(createdAt);
        const formattedDate = `${dateObject.toLocaleDateString()} ${dateObject.toLocaleTimeString()}`;
        return   <p className='flex items-center'>
        <MdOutlineAccessTime className='mr-1' />
        {formattedDate}
      </p>;
      },
    },
      {
        title: 'Tag status',
        dataIndex: 'deleted',
        key: 'deleted',
        render: (deleted) => {
          return deleted ? <p className='text-red-500'>Tag deleted</p> : <p>Active</p>;
        },
      },
      
    
  ];

  return <Table columns={columns} dataSource={allTags} />;
};

export default Tags;

import React, {useCallback, useEffect, useState} from "react";
import {Button, Input, List, message, Modal, Popconfirm, Progress, Table, Tabs, Tag, Typography, Upload} from "antd";
import {useTranslation} from "react-i18next";
import {DownloadOutlined, UploadOutlined} from "@ant-design/icons";
import axios from "axios";

const SubmissionsModal = ({record, type}) => {
    const {t} = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFilesModalOpen, setIsFilesModalOpen] = useState(false);
    const [modalType, setModalType] = useState('newSubmissions');
    const [submissions, setSubmissions] = useState([]);
    const [submissionsFiles, setSubmissionsFiles] = useState([]);
    const [submissionDetails, setSubmissionDetails] = useState({});

    const getAllSubmissions = useCallback(() => {
        if (isModalOpen) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/submissions?jobStatusId=${record._id}`, {
                    withCredentials: true,
                })
                .then((res) => {
                    setSubmissions(res.data.submissions);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [isModalOpen]);

    useEffect(() => {
        getAllSubmissions()
    }, [getAllSubmissions])

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const addNewSubmission = () => {
        axios
            .post(`${process.env.REACT_APP_API_URL}/submissions`, {
                jobStatusId: record._id,
                files: submissionsFiles.map(t => ({
                    type: t.type,
                    fileName: t.fileName,
                    file: t.key,
                }))
            }, {
                withCredentials: true,
            })
            .then((res) => {
                setIsFilesModalOpen(false);
                setSubmissionsFiles([]);
                setModalType('newSubmissions');
                getAllSubmissions();
            })
            .catch((err) => {
                message.error(err.message)
                console.log(err);
            });
    }

    const updateSubmissions = (id, status) => {
        axios
            .put(`${process.env.REACT_APP_API_URL}/submissions/${id}`, {
                status
            }, {
                withCredentials: true,
            })
            .then((res) => {
                setIsFilesModalOpen(false);
                setSubmissionsFiles([]);
                setModalType('newSubmissions');
                getAllSubmissions();
            })
            .catch((err) => {
                message.error(err.message)
            });
    }

    const downloadFile = (index) => {
        axios
            .post(`${process.env.REACT_APP_API_URL}/file/get`, {
                file: `${submissionDetails._id}-${index}`
            }, {
                withCredentials: true,
            })
            .then((res) => {
                if (res.data.url) {
                    let aTag = document.createElement('a');
                    aTag.download = true;
                    aTag.target = '_blank'
                    aTag.href = res.data.url;
                    document.body.appendChild(aTag);
                    aTag.click();
                    document.body.removeChild(aTag);
                }
            })
            .catch((err) => {
                message.error(err.message)
            });
    }

    const props = {
        beforeUpload: file => {
            const maxFileSize = 2 * 1024 * 1024 * 1024; // 2GB limit
            if (file.size > maxFileSize) {
                message.error(`${file.name} file upload failed (exceeds 2GB)`);
                console.error("File size exceeds the limit of 2GB");
                return false;
            }
            return true;
        },
        customRequest: async ({file, onProgress, onSuccess, onError}) => {
            try {
                let response = await axios.post(`${process.env.REACT_APP_API_URL}/file/create`, {
                    fileName: file.name,
                    jobStatusId: record._id
                });

                const signedUrl = response.data.url;
                const key = response.data.key;
                await axios.put(signedUrl, file, {
                    headers: {
                        'Content-Type': file.type
                    },
                    onUploadProgress: (progressEvent) => {
                        const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        onProgress({percent});
                    },
                });
                onSuccess(null, {...file, key});
            } catch (error) {
                message.error(error)
                onError(error);
            }
        },
        onChange: ({file, fileList}) => {
            setSubmissionsFiles(fileList.map(f => ({
                ...f,
                percent: f.percent || 0,
                fileName: f.name,
                key: f.xhr?.key || '',
                type: 'FILE',
            })))
        },
    };

    const columns = [
        {
            title: t("Submission Date"),
            dataIndex: "createdAt",
            render: (text) => <p>{
                new Date(text).toLocaleDateString('en-US', {
                    hour: 'numeric',
                    minute: '2-digit',
                    hour12: true
                })
            }</p>,
            sorter: (a, b) => {
                const date1 = new Date(a.createdAt).toLocaleString();
                const date2 = new Date(b.createdAt).toLocaleString();

                return new Date(date2) - new Date(date1);
            },
            defaultSortOrder: null,
        },
        {
            title: t("Status"),
            dataIndex: "status",
            render: (text) => (
                <span className="flex items-center gap-2">
                      {text === "PENDING" && (
                          <Tag className="bg-yellow-500 text-white font-bold">
                              PENDING
                          </Tag>
                      )}
                    {text === "REJECTED" && (
                        <Tag className="bg-red-500 text-white font-bold">Rejected</Tag>
                    )}
                    {text === "APPROVED" && (
                        <Tag className="bg-green-500 text-white font-bold">Approved</Tag>
                    )}
                </span>
            ),
        },
        {
            title: t("Action"),
            render: (record) => (
                <>
                    <Button className={'me-2'} onClick={() => {
                        setIsFilesModalOpen(true);
                        setModalType('viewFiles');
                        setSubmissionsFiles(record.files);
                        setSubmissionDetails(record);
                    }}>
                        View files
                    </Button>
                    {type === 'client' && record.status === 'PENDING' && <>
                        <Popconfirm
                            placement="topRight"
                            title="Approve."
                            description={<>
                                <p>Marking this approve will automatically mark the order as completed.</p>
                                <p>Please review all the work completed by a professional before you approve the
                                    submissions.</p>
                            </>}
                            okButtonProps={{
                                className: 'text-white bg-blue-500'
                            }}
                            onConfirm={() => updateSubmissions(record._id, 'APPROVED')}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="primary"
                                    className="text-white bg-blue-500 me-2">
                                Mark as approved
                            </Button>
                        </Popconfirm>
                        <Button danger={true} onClick={() => {
                            updateSubmissions(record._id, 'REJECTED');
                        }}>
                            Mark as rejected
                        </Button>
                    </>}
                </>

            ),
        },
    ];

    const filesColumns = [
        {
            title: t("File type"),
            dataIndex: "type",
            render: (text) => <p>{text}</p>
        },
        {
            title: t("Name"),
            dataIndex: "fileName",
            render: (text) => <p className={'line-clamp-1'}>{text}</p>
        },
        {
            title: t("Download file"),
            render: (text, record, index) => (
                record.type === 'LINK' ? '-' :
                    <Button icon={<DownloadOutlined/>} onClick={() => downloadFile(index)}>
                        Download
                    </Button>
            ),
        },
    ];

    const items = [
        {
            key: 1,
            label: 'Upload files',
            children: <>
                <Upload {...props} listType="picture" showUploadList={true} className="relative">
                    <Button icon={<UploadOutlined/>}>Upload Your Work here</Button>
                </Upload>
                {/*<List*/}
                {/*    itemLayout="horizontal"*/}
                {/*    dataSource={submissionsFiles}*/}
                {/*    renderItem={(file) => (*/}
                {/*        <List.Item>*/}
                {/*            <List.Item.Meta*/}
                {/*                title={file.fileName}*/}
                {/*                description={<><Progress percent={file.percent}/></>}*/}
                {/*            />*/}
                {/*        </List.Item>*/}
                {/*    )}*/}
                {/*/>*/}
            </>
        },
        {
            key: 2,
            label: 'Add link',
            children: <>
                <Typography.Title level={5}>Enter your work link</Typography.Title>
                <Input placeholder={'eg: https://github.com/user/work'} onChange={(e) => {
                    setSubmissionsFiles(prevState => {
                        let files = [...(prevState || [])];
                        let findLink = files.findIndex(t => t.type === 'LINK');
                        if (findLink === -1) {
                            files.push({
                                file: e.target.value,
                                type: 'LINK'
                            })
                        } else {
                            files[findLink].file = e.target.value;
                        }
                        return [...files];
                    })
                }}></Input>
            </>
        }
    ]

    return (
        <>
            <Button
                type="primary"
                className="text-white bg-blue-500 border-white"
                onClick={showModal}
            >
                {t("View submission")}
            </Button>
            <Modal
                width={1000}
                centered
                open={isModalOpen}
                onOk={handleOk}
                okType="default"
                okText="Ok"
                cancelText="cancel"
                onCancel={handleCancel}
            >
                <div className="flex space-y-6 flex-col ">
                    <div>
                        <p className="font-bold text-xl text-[#001529]">
                            {t("Submissions")}
                        </p>
                    </div>
                    <div className="">
                        {
                            record.status === "ACTIVE" && type !== 'client' && <div className={'float-right mb-2'}>
                                <Button onClick={() => {
                                    setIsFilesModalOpen(true);
                                    setModalType('newSubmissions')
                                }}>Add new submissions</Button>
                            </div>
                        }
                        <Table columns={columns} dataSource={submissions}/>
                    </div>
                </div>
            </Modal>

            <Modal
                width={800}
                centered
                open={isFilesModalOpen}
                onOk={() => addNewSubmission()}
                okType="default"
                okText="Ok"
                cancelText="cancel"
                onCancel={() => {
                    setIsFilesModalOpen(false);
                    setSubmissionsFiles([]);
                }}
            >
                <div className="flex space-y-6 flex-col ">
                    <div>
                        <p className="font-bold text-xl text-[#001529]">
                            {t(modalType === 'newSubmissions' ? "New submission" : "Submissions files")}
                        </p>
                    </div>
                    <div className="flex flex-col justify-center text-[#001529] text-lg">
                        {modalType === 'newSubmissions' ?
                            <Tabs defaultActiveKey="1" items={items} onChange={() => null}/>
                            : <Table columns={filesColumns} dataSource={submissionsFiles}/>}
                    </div>
                </div>
            </Modal>
        </>
    );
};
export default SubmissionsModal;

import React, { useContext, useState } from "react";
import { Form, Input, Button, message, Radio, Checkbox, Spin } from "antd";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../Context/UserContext";

const Signup = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setSignupData } = useContext(UserContext);
  const BaseUrl = process.env.REACT_APP_API_URL;

  const onFinish = (values) => {
    setLoading(true);
    const { name, email, password, confirmPassword } = values;

    if (password !== confirmPassword) {
      message.error("Passwords do not match");
      setLoading(false);
      return;
    }

    const apiUrl = `${BaseUrl}/users/signup`;
    const requestData = { name, email, password };

    axios
      .post(apiUrl, requestData, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        const responseData = response.data;
        setSignupData(responseData.data);
        message.success("Signup successful!");
        setTimeout(() => {
          navigate("/app/professional/newDeals");
        }, 1500);
      })
      .catch((error) => {
        console.error("Signup Error:", error);
        message.error(error.response.data.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <section className="bg-gray-900 min-h-screen flex justify-center items-center p-2">
      <div className="w-full max-w-md bg-white rounded-lg shadow-md p-4">
        <div className="flex items-center space-x-3">
          <img
            className="w-10 h-10"
            src="../../../images/demologo.png"
            alt="logo"
          />
          <p className="text-3xl font-bold text-gray-900">Wory App</p>
        </div>
        <h1 className="text-xl font-bold leading-tight text-gray-900 md:text-2xl">
          {t("Sign in to your account")}
        </h1>
        <Spin spinning={loading} delay={500} size="large">
          <Form
            name="signup"
            layout="vertical"
            onFinish={onFinish}
            className="space-y-2"
          >
            <Form.Item
              label={t(" Name *")}
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter your name",
                },
              ]}
            >
              <Input placeholder="Enter Your First Name" />
            </Form.Item>
            {/* <Form.Item
                  label={t("Last Name *")}
                  name="lastName"
                  rules={[{ required: true, message: "Please enter your Last name" }]}
                >
                  <Input placeholder="Enter Your Last Name" />
                </Form.Item> */}
            <Form.Item
              label={t("Your email *")}
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter your email",
                },
                {
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}
            >
              <Input placeholder="name@company.com" />
            </Form.Item>
            <Form.Item
              label={t("Password *")}
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter a password",
                },
                {
                  min: 8,
                  message:
                    "Password must be at least 8 characters",
                },
                {
                  min:8,
                  validator(_, value) {
                  
                    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
                    if (!specialCharRegex.test(value)) {
                      return Promise.reject(
                        new Error("Password must contain at least one special character")
                      );
                    }
                    return Promise.resolve();
                  },
                }
              ]}
            >
              <Input.Password placeholder="••••••••" />
            </Form.Item>
            <Form.Item
              label={t("Confirm Password *")}
              name="confirmPassword"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value ||
                      getFieldValue("password") === value
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Passwords do not match")
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="••••••••" />
            </Form.Item>
            {/* <Form.Item
              label={t("Select Category *")}
              name="role"
              rules={[
                {
                  required: true,
                  message: t("Please select a category"),
                },
              ]}
            >
              <Radio.Group>
                <Radio value="Client">{t("Client")}</Radio>
                <Radio value="Professional">
                  {t("Professional")}
                </Radio>
              </Radio.Group>
            </Form.Item> */}

          

            <Form.Item>
              <Button
                type="primary"
                style={{ backgroundColor: "#1677ff" }}
                htmlType="submit"
                className="w-full"
              >
                {t("Sign Up")}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
        {/* <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                  {t(
                    "I agree to be contacted by Open PRO about this offer as per the Open PRO Privacy Policy."
                  )}
                </p> */}
        <p className="text-sm font-light text-gray-500 dark:text-gray-400">
          {t("Already have an account.")}{" "}
          <Link
            to="/login"
            className="font-medium text-blue-600 hover:underline dark:text-blue-500"
          >
            {t("Login")}
          </Link>
        </p>
        <p className="text-sm font-light text-gray-500 dark:text-gray-400">
          {t("Go Back to Home")}{" "}
          <Link
            to="/"
            className="font-medium text-blue-600 hover:underline dark:text-blue-500"
          >
            {t("Home")}
          </Link>
        </p>
      </div>
    </section>
  );
};

export default Signup;

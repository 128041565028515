import React, {useCallback, useEffect, useState} from "react";
import {Form, Input, Button, Select, Table, Tag, message, Tabs, Typography, Modal} from "antd";
import {useTranslation} from "react-i18next";
import axios from "axios";

// const { Option } = Select;
const Withdraw = () => {
    const {t} = useTranslation();
    const [walletBalance, setWalletBalance] = useState(0);
    const [walletPendingBalance, setWalletPendingBalance] = useState(0);
    const [walletTransactions, setWalletTransactions] = useState([]);
    const [payoutTransactions, setPayoutTransactions] = useState([]);
    const [showDetails, setShowDetails] = useState(false);
    const [payout, setPayout] = useState(null);

    const [form] = Form.useForm();

    const getWallet = useCallback(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/wallet`, {
                withCredentials: true,
            })
            .then((res) => {
                let available = (res.data.wallet.amount || 0) - (res.data.totalPending || 0);
                setWalletBalance(available < 0 ? 0 : available);
                setWalletPendingBalance(res.data.totalPending || 0);
                setWalletTransactions(res.data.walletTransactions);
                setPayoutTransactions(res.data.payoutTransactions);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [])

    useEffect(() => {
        getWallet()
    }, [getWallet]);

    const columns = [
        {
            title: t("Transaction Date"),
            dataIndex: "createdAt",
            render: (text) => <p>{
                new Date(text).toLocaleDateString('en-US', {
                    hour: 'numeric',
                    minute: '2-digit',
                    hour12: true
                })
            }</p>,
            sorter: (a, b) => {
                const date1 = new Date(a.createdAt).toLocaleString();
                const date2 = new Date(b.createdAt).toLocaleString();

                return new Date(date2) - new Date(date1);
            },
            defaultSortOrder: null,
        },
        {
            title: t("Transaction Id"),
            dataIndex: "transactionId",
            render: (text) => <p>{text}</p>
        },
        {
            title: t("Amount"),
            dataIndex: "amount",
            render: (text) => <p>${text}</p>
        },
        {
            title: t("Status"),
            dataIndex: "status",
            render: (text, record) => (
                <span className="flex items-center gap-2">
                    {text === "DEBITED" && (
                        <Tag className="bg-red-500 text-white font-bold">Debit</Tag>
                    )}
                    {text === "CREDITED" && (
                        record.isReleased ?
                            <Tag className="bg-green-500 text-white font-bold">Credit</Tag> :
                            <Tag className="bg-yellow-500 text-white font-bold">Pending</Tag>
                    )}

                </span>
            ),
        },
    ];

    const payoutColumns = [
        {
            title: t("Request Date"),
            dataIndex: "createdAt",
            render: (text) => <p>{
                new Date(text).toLocaleDateString('en-US', {
                    hour: 'numeric',
                    minute: '2-digit',
                    hour12: true
                })
            }</p>,
            sorter: (a, b) => {
                const date1 = new Date(a.createdAt).toLocaleString();
                const date2 = new Date(b.createdAt).toLocaleString();

                return new Date(date2) - new Date(date1);
            },
            defaultSortOrder: null,
        },
        {
            title: t("Amount"),
            dataIndex: "amount",
            render: (text) => <p>${text}</p>
        },
        {
            title: t("Status"),
            dataIndex: "status",
            render: (text) => (
                <span className="flex items-center gap-2">
                    {text === "IN_PROGRESS" && (
                        <Tag className="bg-yellow-500 text-white font-bold">
                            In progress
                        </Tag>
                    )}
                    {text === "IN_QUEUE" && (
                        <Tag className="bg-yellow-500 text-white font-bold">
                            In Queue
                        </Tag>
                    )}
                    {text === "PENDING" && (
                        <Tag className="bg-yellow-500 text-white font-bold">
                            Pending
                        </Tag>
                    )}
                    {text === "ERROR" && (
                        <Tag className="bg-red-500 text-white font-bold">ERROR</Tag>
                    )}
                    {text === "DECLINED" && (
                        <Tag className="bg-red-500 text-white font-bold">Declined</Tag>
                    )}
                    {text === "COMPLETED" && (
                        <Tag className="bg-green-500 text-white font-bold">Completed</Tag>
                    )}
                </span>
            ),
        },
        {
            title: t("Action"),
            dataIndex: "action",
            render: (text, record) => (
                record.status === 'ERROR' &&
                <>
                    <Button type="primary" className="text-white bg-blue-500"
                            onClick={() => {
                                setPayout(record);
                                setShowDetails(true)
                            }}>View</Button>
                </>
            ),
        },
    ];

    const onFinish = (values) => {
        axios
            .post(`${process.env.REACT_APP_API_URL}/payout`, {
                amount: values.amount
            }, {
                withCredentials: true,
            })
            .then((res) => {
                getWallet();
                form.setFieldValue('amount', 0);
            })
            .catch((err) => {
                message.error(err?.response?.data?.msg || err.message);
            });
    };
    return (
        <>
            <div className="flex justify-center mb-4">
                <div className="bg-white p-8 rounded shadow-md lg:w-[50%]">
                    <div>
                        <div>
                            <h2 className="text-xl font-semibold mb-4">
                                Available Balance : ${walletBalance}*
                            </h2>
                            <h2 className="text-xl font-semibold mb-4">
                                Pending Balance : ${walletPendingBalance}*
                            </h2>
                        </div>
                    </div>
                    <h2 className="text-xl font-semibold mb-4">{t("Withdraw Funds")}</h2>
                    <Form
                        form={form}
                        name="withdrawalForm"
                        onFinish={onFinish}
                        initialValues={{currency: "usd"}}
                    >
                        <Form.Item
                            label={t("Amount")}
                            name="amount"
                            rules={[
                                {
                                    required: true,
                                    message: t("Please enter the withdrawal amount"),
                                },
                            ]}
                        >
                            <Input type="number"/>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                style={{backgroundColor: "#1677ff"}}
                                htmlType="submit"
                                className="w-full"
                            >
                                {t("Withdraw")}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
            <Tabs items={[
                {
                    key: 1,
                    label: 'Wallet transactions',
                    children: <Table columns={columns} dataSource={walletTransactions}/>
                },
                {
                    key: 2,
                    label: 'Payout transactions',
                    children: <Table columns={payoutColumns} dataSource={payoutTransactions}/>
                }
            ]}/>
            <Modal
                width={800}
                centered
                open={showDetails}
                onOk={() => setShowDetails(false)}
                okType="default"
                okText="Ok"
                cancelText="Cancel"
                onCancel={() => setShowDetails(false)}
            >
                <div className="flex space-y-6 flex-col ">
                    <div>
                        <p className="font-bold text-xl text-[#001529]">
                            {t("Payout Details")}
                        </p>
                    </div>
                    <div className="">
                        <p>{t("Freelancer Name")} : {payout?.requestUser?.name}</p>
                        <p>{t("Request date")} : {new Date(payout?.createdAt).toLocaleDateString('en-US', {
                            hour: 'numeric',
                            minute: '2-digit',
                            hour12: true
                        })}</p>
                        <p>{t("Amount")} : ${payout?.amount}</p>
                        <p className={'flex items-center mb-4'}>{t("Status")} :
                            <span className="flex items-center gap-2 ms-2">
                                {payout?.status === "IN_PROGRESS" && (
                                    <Tag className="bg-yellow-500 text-white font-bold">
                                        In progress
                                    </Tag>
                                )}
                                {payout?.status === "IN_QUEUE" && (
                                    <Tag className="bg-yellow-500 text-white font-bold">
                                        In Queue
                                    </Tag>
                                )}
                                {payout?.status === "PENDING" && (
                                    <Tag className="bg-yellow-500 text-white font-bold">
                                        Pending
                                    </Tag>
                                )}
                                {payout?.status === "ERROR" && (
                                    <Tag className="bg-red-500 text-white font-bold">ERROR</Tag>
                                )}
                                {payout?.status === "DECLINED" && (
                                    <Tag className="bg-red-500 text-white font-bold">Declined</Tag>
                                )}
                                {payout?.status === "COMPLETED" && (
                                    <Tag className="bg-green-500 text-white font-bold">Completed</Tag>
                                )}
                            </span>
                        </p>

                        {payout?.status === 'ERROR' && <>
                            <Typography.Title level={5}>Details required to complete this payment.</Typography.Title>
                            {payout?.requiredDetails.map((item, index) => (
                                <div className={'flex mb-2'}>
                                    <p>{item.name} : {item?.value}</p>
                                </div>
                            ))}
                        </>}

                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Withdraw;

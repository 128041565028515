import React from "react";
import { Popover } from "antd";
import { IoLogoWechat } from "react-icons/io5";
import Chat from "./Chat";

const ChatButton = () => {

  const content = (
    <div>
     <Chat/>
    </div>
  );

  return (
    <Popover
      content={content}
      placement="bottomRight" 
      trigger="click"
      className="fixed bottom-24 right-10 bg-[#002140] p-2  rounded-full" 
    >
      <IoLogoWechat className="text-6xl cursor-pointer text-[#2563eb]" />
    </Popover>
  );
};

export default ChatButton;

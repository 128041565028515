import React from 'react';
import Chart from 'react-google-charts';

function PieChart() {
  const data = [
    ['Task', 'Hours per Day'],
    ['Applied Jobs', 6],
    ['Posted Jobs', 7],
    ['Active Bids', 2],
  ];

  const options = {
    pieHole: 0.4,
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }} className=' w-[100%]'>
      <div>
        <h2 style={{ fontSize: '24px', marginBottom: '20px' }}>Static Analytics</h2>
      </div>
      <div className='lg:w-[90%] ms-10'>  <Chart
        chartType="PieChart"
        width="100%"
        height="400px"
        data={data}
        options={options}
      />
      
      </div>
    
    </div>
  );
}

export default PieChart;

import { React, useContext, useEffect, useState } from "react";
import { Table, Space, Button, Modal, Spin, Checkbox, Radio } from "antd";
import AcceptButton from "./AcceptButton";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { JobContext } from "../../Context/JobContext";
import { UserContext } from "../../Context/UserContext";

const TruncatedColumn = ({ text }) => {
  const { t } = useTranslation();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div
        style={{
          maxWidth: 300,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {text}
      </div>
      {text.length > 50 && (
        <>
          <Button type="link" onClick={showModal} className="relative right-4">
            {t("See More")}
          </Button>
          <Modal
            title="Full Description"
            visible={isModalVisible}
            onOk={handleOk}
            okType="default"
            okText="Accept"
            cancelText="Close"
            onCancel={handleCancel}
          >
            <p>{text}</p>
          </Modal>
        </>
      )}
    </>
  );
};

const onChange = (pagination, filters, sorter, extra) => {
  console.log("params", pagination, filters, sorter, extra);
};
const NewDeals = () => {
  const { t } = useTranslation();
  const { allJobs, setAllJobs, setReviewJobs } = useContext(JobContext);
  const [initialLoading, setInitialLoading] = useState(true);

  const uniqueTags = Array.from(
    new Set(allJobs.flatMap((job) => job.tagAssociated.map((tag) => tag.name)))
  );

  allJobs.sort((job1, job2) => {
    const date1 = new Date(job1.createdAt).toLocaleString();
    const date2 = new Date(job2.createdAt).toLocaleString();

    return new Date(date2) - new Date(date1);
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setInitialLoading(true);
        const [allJobsResponse, inReviewResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/jobs/status/in-review`, {
            withCredentials: true,
          }),
        ]);

        const filteredJobs = allJobsResponse?.data?.jobs.filter(
          (job) =>
            !inReviewResponse.data.reviewJobs.some(
              (reviewJob) => reviewJob.jobId?._id === job._id
            )
        );
        setAllJobs(filteredJobs);
        setReviewJobs(inReviewResponse?.data.reviewJobs);
      } catch (error) {
        console.log("Error", error);
      } finally {
        setInitialLoading(false);
      }
    };

    fetchData();
  }, [setAllJobs, setReviewJobs]);
useEffect(()=>{
  axios.get(`${process.env.REACT_APP_API_URL}/jobs`).then(res=>{
    setInitialLoading(false);
    setAllJobs(res?.data.jobs);
  })
},[])

  const columns = [
    {
      title: t("Price"),
      dataIndex: "price",
      // sorter: (a, b) => a.minPrice - b.minPrice,
      render: (text, record) => (
        <span>
          ${record.price}
        </span>
      ),
      width: "15%",
    },
    {
      title: t("Title"),
      dataIndex: "title",
      width: "15%",
    },
    {
      title: t("Description"),
      dataIndex: "description",
      render: (text) => <TruncatedColumn text={text} />,
      width: "20%",
    },
    // {
    //   title: t("Time Duration"),
    //   dataIndex: "hoursReqd",
    //   render: (text) => <span>{text} hours</span>,
    //   filters: [
    //     { text: "0-2 hours", value: "0-2" },
    //     { text: "2-4 hours", value: "2-4" },
    //     { text: "4-6 hours", value: "4-6" },
    //     { text: "6-8 hours", value: "6-8" },
    //   ],
    //   onFilter: (value, record) => {
    //     const [min, max] = value.split("-").map(Number);
    //     return record.hoursReqd >= min && record.hoursReqd <= max;
    //   },
    //   filterMultiple: false,
    //   filterDropdown: ({
    //     setSelectedKeys,
    //     selectedKeys,
    //     confirm,
    //     clearFilters,
    //   }) => (
    //     <div style={{ padding: 8 }} className="flex flex-col">
    //       <Radio.Group
    //         className="flex flex-col p-2"
    //         options={[
    //           { label: "0-2 hours", value: "0-2" },
    //           { label: "2-4 hours", value: "2-4" },
    //           { label: "4-6 hours", value: "4-6" },
    //           { label: "6-8 hours", value: "6-8" },
    //         ]}
    //         value={selectedKeys[0]}
    //         onChange={(e) => setSelectedKeys([e.target.value])}
    //         verticle={true}
    //       />
    //       <div style={{ marginTop: 8 }}>
    //         <Button
    //           onClick={() => confirm()}
    //           style={{ marginRight: 8 }}
    //           type="primary"
    //           className="text-white bg-blue-500"
    //         >
    //           OK
    //         </Button>
    //         <Button onClick={() => clearFilters()}>Reset</Button>
    //       </div>
    //     </div>
    //   ),
    // },
    {
      title: t("Area"),
      dataIndex: "tagAssociated",
      render: (tagAssociated) => tagAssociated[0]?.name,
      filters: uniqueTags.map((area) => ({
        text: t(area),
        value: area,
      })),
      onFilter: (value, record) =>
        record.tagAssociated.some((tag) => tag.name === value),
      filterMultiple: true,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <>
          <div style={{ height: 120, overflowX: "auto", padding: "8px" }}>
            {" "}
            <Checkbox.Group
              className="flex flex-col "
              options={uniqueTags.map((area) => ({
                label: t(area),
                value: area,
              }))}
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e)}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "5px",
              margin: "5px",
            }}
          >
            <Button
              onClick={() => confirm()}
              style={{ marginRight: 8 }}
              type="primary"
              className="text-white bg-blue-500"
            >
              OK
            </Button>
            <Button onClick={() => clearFilters()}>Reset</Button>
          </div>
        </>
      ),
    },
    {
      title: t("Priority"),
      dataIndex: "priority",
      filters: [
        {
          text: t("HIGH"),
          value: "HIGH",
        },
        {
          text: t("MEDIUM"),
          value: "MEDIUM",
        },
        {
          text: t("LOW"),
          value: "LOW",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.priority.startsWith(value),
      width: "15%",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8, height: 150, overflow: "auto" }}>
          <div className="flex flex-col">
            <Checkbox.Group
              className="flex flex-col"
              options={[
                { label: t("HIGH"), value: "HIGH" },
                { label: t("MEDIUM"), value: "MEDIUM" },
                { label: t("LOW"), value: "LOW" },
              ]}
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e)}
              vertical
            />
          </div>

          <div style={{ marginTop: 8 }}>
            <Button
              onClick={() => confirm()}
              style={{ marginRight: 8 }}
              type="primary"
              className="text-white bg-blue-500"
            >
              OK
            </Button>
            <Button onClick={() => clearFilters()}>Reset</Button>
          </div>
        </div>
      ),
    },
    {
      title: t("Action"),
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <AcceptButton record={record} />
        </Space>
      ),
      width: "10%",
    },
  ];
  return (
    <div className="lg:space-y-4">
      {initialLoading ? (
        <div className=" min-h-[50vh] flex justify-center align-middle items-center">
          {" "}
          <Spin size="large" />
        </div>
      ) : (
        <div className="overflow-scroll">
          <Table
            columns={columns}
            dataSource={allJobs}
            onChange={onChange}
            bordered
          />
        </div>
      )}
    </div>
  );
};
export default NewDeals;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./i18n";
import axios from "axios";
import UserAuthContext from "./Context/UserContext";
import JobsContext from "./Context/JobContext";
import ClientsContext from "./Context/ClientContext";
import AdminContext from "./Context/AdminContext";
import SocketsContext from "./Context/SocketContext";
import NotificationsContext from "./Context/NotificationContext";
axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AdminContext>
  <ClientsContext>
    <JobsContext>
      <UserAuthContext>
        <NotificationsContext>
            <SocketsContext>
              <App />
            </SocketsContext>
        </NotificationsContext>
      </UserAuthContext>
    </JobsContext>
  </ClientsContext>
  </AdminContext>
);

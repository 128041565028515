import React from "react";
import { Tabs } from "antd";
import CompletedClient from "./ComplaintsTables/Client/Completed";
import PendingClient from "./ComplaintsTables/Client/Pending";
import RejectedClient from "./ComplaintsTables/Client/Rejected";
import PendingFreelancer from "./ComplaintsTables/Freelancer/Pending";
import CompletedFreelancer from "./ComplaintsTables/Freelancer/Completed";
import RejectedClientFreelancer from "./ComplaintsTables/Freelancer/Rejected";

const { TabPane } = Tabs;

const Complaints = () => {
  return (
    <Tabs defaultActiveKey="client">
      <TabPane tab="Client" key="client">
        <Tabs defaultActiveKey="pending">
          <TabPane tab="Pending" key="pending">
            <div className="bg-yellow-500 rounded-md p-3">
              <p className="text-white font-medium text-lg">Pending Requests</p>
            </div>
            <PendingClient />
          </TabPane>
          <TabPane tab="Completed" key="completed">
            <div className="bg-green-500 rounded-md p-3">
              <p className="text-white font-medium text-lg">Completed Orders</p>
            </div>
            <CompletedClient />
          </TabPane>
          <TabPane tab="Rejected" key="rejected">
            <div className="bg-red-500 rounded-md p-3">
              <p className="text-white font-medium text-lg">
                Rejected Requests
              </p>
            </div>
            <RejectedClient />
          </TabPane>
        </Tabs>
      </TabPane>
      <TabPane tab="Freelancer" key="freelancer">
        <Tabs defaultActiveKey="pending">
          <TabPane tab="Pending" key="pending">
            <div className="bg-yellow-500 rounded-md p-3">
              <p className="text-white font-medium text-lg">Pending Requests</p>
            </div>
            <PendingFreelancer />
          </TabPane>
          <TabPane tab="Completed" key="completed">
            <div className="bg-green-500 rounded-md p-3">
              <p className="text-white font-medium text-lg">Completed Orders</p>
            </div>
            <CompletedFreelancer />
          </TabPane>
          <TabPane tab="Rejected" key="rejected">
            <div className="bg-red-500 rounded-md p-3">
              <p className="text-white font-medium text-lg">
                Rejected Requests
              </p>
            </div>
            <RejectedClientFreelancer />
          </TabPane>
        </Tabs>
      </TabPane>
    </Tabs>
  );
};

export default Complaints;

import React, { useContext, useState } from "react";
import { UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { UserContext } from "../Context/UserContext";
import { Spin, Tag } from "antd";
import { MdVerified } from "react-icons/md";
const UserProfile = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { fullprofile } = useContext(UserContext);
 console.log(fullprofile,"profile");  

  return (
    <div className="container md:p-5">
      <Spin spinning={loading} delay={500} size="large">
        {/* <div className="flex justify-center">
        <div className="lg:w-full space-y-3 lg:grid lg:gap-3 lg:grid-cols-3 items-center">
          <div className="about bg-slate-200 p-5 shadow-sm rounded-md">
            <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
              <span className="text-2xl">{t("Personal Information")}</span>
              <span className="">
                <UserOutlined size={30} />
              </span>
            </div>
            <div className="grid text-gray-700 text-base mt-4">
              <div className="grid md:grid-cols-3">
                <div className="px-4 py-2 font-semibold">Name</div>
                <div className="px-4 py-2">{fullprofile?.data?.name}</div>
              </div>

              <div className="grid md:grid-cols-3">
                <div className="px-4 py-2 font-semibold">Role</div>
                <div className="px-4 py-2">{fullprofile?.data.role}</div>
              </div>

              <div className="grid md:grid-cols-3">
                <div className="px-4 py-2 font-semibold">Account Created </div>
                <div className="px-4 py-2">
                  {" "}
                  {fullprofile &&
                    new Date(fullprofile?.data.createdAt).toLocaleDateString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }
                    )}
                </div>
              </div>
              <div className="grid md:grid-cols-3">
                <div className="px-4 py-2 font-semibold">Email ID</div>
                <div className="px-4 py-2">
                  <p>
                    {fullprofile?.data.email}
                  </p>
                </div>
              </div>
              <div className="grid md:grid-cols-3">
                <div className="px-4 py-2 font-semibold">Last Login</div>
                <div className="px-4 py-2">
                  {new Date(fullprofile?.data?.updatedAt).toLocaleTimeString()}{" "}
                  {fullprofile
                    ? new Date(fullprofile.data.updatedAt).toLocaleDateString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }
                      )
                    : "1990"}
                </div>
              </div>
            </div>
          </div>

       
        </div>
        </div>
      */}
      <div className="flex justify-center">
        <div>
        <div className="">
          <div className="about bg-slate-200 p-5 shadow-sm rounded-md">
            <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
              <span className="text-2xl underline">{t("Personal Information")}</span>
              <span className="">
                <UserOutlined size={30} />
              </span>
            </div>
            <div className="px-4 py-2 text-lg text-center">{fullprofile?.data?.bio}</div>

            <div className="grid text-gray-700 text-base mt-4">
              <div className="grid md:grid-cols-3">
                <div className="px-4 py-2 font-semibold">Name</div>
                <div className="px-4 py-2">{fullprofile?.data?.name}</div>
              </div>

              <div className="grid md:grid-cols-3">
                <div className="px-4 py-2 font-semibold">Role</div>
                <div className="px-4 py-2">{fullprofile?.data.role}</div>
              </div>

              <div className="grid md:grid-cols-3">
                <div className="px-4 py-2 font-semibold">Account Created </div>
                <div className="px-4 py-2">
                  {" "}
                  {fullprofile &&
                    new Date(fullprofile?.data.createdAt).toLocaleDateString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }
                    )}
                </div>
              </div>
              <div className="grid md:grid-cols-3">
                <div className="px-4 py-2 font-semibold">Email ID</div>
                <div className="px-4 py-2">
                  <p className="flex items-center">
                    {fullprofile?.data.email} {fullprofile?.data.emailVerified && <MdVerified color="blue"/>}
                  </p>
                </div>
              </div>
              <div className="grid md:grid-cols-3">
                <div className="px-4 py-2 font-semibold">Last Login</div>
                <div className="px-4 py-2">
                  {new Date(fullprofile?.data?.updatedAt).toLocaleTimeString()}{" "}
                  {fullprofile
                    ? new Date(fullprofile.data.updatedAt).toLocaleDateString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }
                      )
                    : "1990"}
                </div>
              </div>
            </div>
            <div className="flex justify-center mt-4">
      <Tag color="blue" className="p-2 text-[15px] font-bold"> {fullprofile?.data.jobsPosted} Job Posted</Tag>
      <Tag color="blue" className="p-2 text-[15px] font-bold">{fullprofile?.data.moneyEarned} Money Earned</Tag>
      <Tag color="blue" className="p-2 text-[15px] font-bold">{fullprofile?.data.jobsCompleted} Job Completed</Tag>
    </div>
          </div>

       
        </div>

        </div>
      </div>
      </Spin>
    </div>
  );
};

export default UserProfile;

import { useTranslation } from "react-i18next";
import React from "react";

const Step1 = ({Image1}) => {
  const { t } = useTranslation();
  return (
    <div className="p-8 flex items-center justify-center">
      <div className=" text-white flex flex-col items-center">
        <p className="font-semibold text-3xl">
          {t("Sign Up and Create a Profile")}
        </p>
        <div className="lg:flex">
          <img
            className="lg:w-2/6 md:w-3/6 w-5/6 mb-4 object-cover rounded"
            src={Image1}
            alt=""
          />
          <p className="grid items-center text-lg text-center">
            {t(
              "Join our community by signing up. Create a profile showcasing yourskills, experience, and portfolio. Clients will be able to see your expertise and choose you for their projects."
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Step1;

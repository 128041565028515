import { createContext, useState } from "react";

export const ClientContext = createContext();


const ClientsContext = ({ children }) => {
    const [postedJobs, setPostedJobs] = useState([]);
    const [pendingJobs, setPendingJobs] = useState([]);
    const [notstartedJobs, setNotStartedJobs] = useState([]);
    const [completeJobs, setCompleteJobs] = useState([]);
    const [loader,setLoader] = useState(false)
    const [clientchatlist, setClientList] = useState([]);
  const [loadingjobs,setLoadingJobs] = useState(false)
  const [clientUserList, setClientUserList] = useState([]);
  const [userTag, setUserTags] = useState([]);
  const [deletjobloader,setDeleteJobLoader] = useState(false)

  return (
    <ClientContext.Provider
      value={{
        postedJobs, setPostedJobs,
        pendingJobs, setPendingJobs,
        completeJobs, setCompleteJobs,
        loader,setLoader,
        clientchatlist, setClientList,
        loadingjobs,setLoadingJobs  , 
        notstartedJobs, setNotStartedJobs,
        clientUserList, setClientUserList   ,
        userTag, setUserTags,
        deletjobloader,setDeleteJobLoader
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};

export default ClientsContext;

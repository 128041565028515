
import React, { useContext, useEffect, useState } from 'react';
import { Table, Button, Spin, Modal } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../../Context/UserContext';
const Rejected = () => {
  const [fullComplaintModalVisible, setFullComplaintModalVisible] = useState(false);
  const [dataPending,setDataPending] = useState([])
 const { freeload,setFreeLoad,jobs,setJobs} =  useContext(UserContext)
  const [selectedComplaint, setSelectedComplaint] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
 const navigate = useNavigate();

 const getPendingClientRequest = () => {
  setFreeLoad(true);
  axios.get(`${process.env.REACT_APP_API_URL}/complaint/my/freelancer`, {
    withCredentials: true
  })
  .then(res => {
    const pendingData = res?.data?.complaints.filter(item => item.status === "rejected");
    setDataPending(pendingData);
    setFreeLoad(false);
  })
  .catch(err => {
    console.log(err);
    setFreeLoad(false);
  });
};

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/jobs`).then(res=>{
      setJobs(res?.data.jobs);
    })
    getPendingClientRequest();
  }, []);
  // const selectedJob = jobs.find(job => {
  //   // Check if any job poster in the current job matches the specified ID
  //   return job.jobPoster.some(jobPoster => jobPoster._id === jobId);
  // });
  
  const handleOpenModal = (item) => {
    setModalVisible(true);
    const selectedJob = jobs.find(job => {
    return job.jobPoster.find(jobPoster => jobPoster._id === item.clientId);
  });
  
  setSelectedItem(selectedJob);
  console.log(selectedJob,"items");
  };

  const handleShowFullComplaint = (complaint) => {
    setSelectedComplaint(complaint);
    setFullComplaintModalVisible(true);
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
    setModalVisible(false);
  };
  const columns = [
   
    {
      title: 'Client Name',
      dataIndex: 'clientName',
      key: 'clientName',
    },
    {
      title: 'Status',
      dataIndex: 'jobStatus',
      key: 'jobStatus',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => <span>{new Date(createdAt).toLocaleString()}</span> // Format date as per your requirement
    },
    {
      title: 'Complaint',
      dataIndex: 'complaint',
      key: 'Complaint',
      render: (complaint) => (
        <div>
          {complaint.length > 30 ? (
            <span>
              {complaint.substr(0, 30)}
              <Button type="link" onClick={() => handleShowFullComplaint(complaint)}>...More</Button>
            </span>
          ) : (
            <span>{complaint}</span>
          )}
        </div>
      ),
    },
  //  {
  //     title: 'Action',
  //     key: 'action',
  //     render: (text, record) => (
  //       <button onClick={() => handleOpenModal(record)}>Details</button>
  //     ),
  //   },
  ];

  const data = Array.isArray(dataPending) // Check if datapending is an array
  ? dataPending
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      .map(item => {
        const client = jobs?.find(job => job?.jobPoster[0]._id === item?.clientId);
        const clientName = client?.jobPoster[0]?.name ?? "Unknown"; // Default to "Unknown" if client not found

        return {
          key: item._id,
          clientName: clientName,
          jobStatus: item.status,
          complaint: item.complaint,
          createdAt: item.createdAt,
          freelancerId: item.freelancerId,
          clientId:item.clientId
          // Assuming createdAt is the timestamp property
        };
      })
  : []; 



  return (
    <>
      <Spin spinning={freeload}>
        <Table columns={columns} dataSource={data} />
      </Spin>
      <Modal
        title="Details"
        visible={modalVisible}
        onCancel={handleCloseModal}
        footer={null}
      >
        <p>Job title: {selectedItem?.title}</p>
        <p>Price: ${selectedItem?.price}</p>
        <p>Priority: {selectedItem?.priority}</p>
        <p>Description: {selectedItem?.description}</p>
        <p>Created At: {selectedItem?.createdAt}</p>

        {/* Add more details as needed */}
      </Modal>
      <Modal
        title="Complaint"
        visible={fullComplaintModalVisible}
        onCancel={() => setFullComplaintModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setFullComplaintModalVisible(false)}>Close</Button>
        ]}
      >
        <p>{selectedComplaint}</p>
      </Modal>
    </>
  );
};

export default Rejected;

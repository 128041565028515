
import React, { useContext, useState } from "react";
import { Button, Checkbox, Radio, Space, Table, Tag, message } from "antd";
import { useTranslation } from "react-i18next";
import TruncatedColumn from "../../../Professional/Orders/TruncatedColumn";
import {  FaRocketchat } from "react-icons/fa";
import { ClientContext } from "../../../../Context/ClientContext";
import Emptyorder from "../../../../images/empty.png"
import OrderDetailButton from "../OrderDetailModal";
import { UserContext } from "../../../../Context/UserContext";
import { useNavigate } from "react-router-dom";
import ComplaintsAdminModal from "../../../ComplaintsAdminModal";
import axios from "axios";


const Completed = () => {
  const { t } = useTranslation();
  const {completeJobs} = useContext(ClientContext);
  const { User,setUserList,userList } = useContext(UserContext);
 const navigate = useNavigate()
 const [modalVisible, setModalVisible] = useState(false);
 const [record,setRecord] = useState({})
 const handleOpenModal = (record) => {
   setModalVisible(true);
   setRecord(record)
 };

  const handleModalCancel = () => {
    setModalVisible(false);
  };
 const HandleChat = async (record) => {
  console.log(record,"chat record")
  if (!User) {
    message.error("Please login to your account first");
  } else {
    const jobExists = userList.some((user) => user.userId._id === record.userId._id);
      await axios.post(`${process.env.REACT_APP_API_URL}/chatrooms`, {
          participants: [record.jobPoster._id, record.userId._id]
      }, {
          withCredentials: true,
      });
    if (jobExists) {
      navigate("/app/client/message");
    } else {
      setUserList([record, ...userList]);
      navigate("/app/client/message");
    }
  }
};
  completeJobs.sort((job1, job2) => {
    const date1 = new Date(job1.createdAt).toLocaleString();
    const date2 = new Date(job2.createdAt).toLocaleString();
    
    return new Date(date2) - new Date(date1);
  });
  const columns = [
    {
      title: t("Action"),
      render: (text, record) => (
        <Space size="middle">
          <OrderDetailButton record={record} />
          {/* <Button className="bg-red-500 text-white">Delete</Button> */}
        </Space>
      ), 
    },
      {
        title: t("Price"),
        dataIndex: ["jobId", "price"],
        render: (text) => <p >${text}</p>,
      },
 
      {
        title: t("Title"),
        dataIndex: ["jobId","title"],
      },
     
      {
        title: t("Description"),
        dataIndex: ["jobId","description"],
        render: (text) => <TruncatedColumn text={text} />,
      },
      {
        title: t("Priority"),
        dataIndex: ["jobId","priority"],
        filters: [
          {
            text: t("HIGH"),
            value: "HIGH",
          },
          {
            text: t("MEDIUM"),
            value: "MEDIUM",
          },
          {
            text: t("LOW"),
            value: "LOW",
          },
        ],
        filterMode: "tree",
        filterSearch: true,
        onFilter: (value, record) => record && record.jobId && record.jobId.priority && record.jobId.priority.startsWith(value),      width: "15%",
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8, height: 150, overflow: 'auto' }}>
            <div className="flex flex-col">
            <Checkbox.Group
            className="flex flex-col"
              options={[
                { label: t("HIGH"), value: "HIGH" },
                { label: t("MEDIUM"), value: "MEDIUM" },
                { label: t("LOW"), value: "LOW" },
              ]}
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e)}
              vertical
            />
            </div>
          
            <div style={{ marginTop: 8 }}>
              <Button
                onClick={() => confirm()}
                style={{ marginRight: 8 }}
                type="primary"
                className="text-white bg-blue-500"
              >
                OK
              </Button>
              <Button onClick={() => clearFilters()}>Reset</Button>
            </div>
          </div>
        ),
      },    
      {
        title: t("Status"),
        dataIndex: "status",
        render: (text) => (
          <span className="flex items-center gap-2">
          {text === "IN_REVIEW" && <Tag className="bg-yellow-500 text-white font-bold">Under Review</Tag>}
          {text === "ACTIVE" && <Tag className="bg-blue-500 text-white font-bold">Active</Tag>}
          {text === "REJECTED" && <Tag className="bg-red-500 text-white font-bold">Rejected</Tag>}
          {text === "COMPLETED" && <Tag className="bg-green-500 text-white font-bold">Completed</Tag>}
        </span>
        ),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }} className="flex flex-col">
            <Checkbox.Group
            className="flex flex-col"
              options={[
                { label: 'Under review', value: 'IN_REVIEW' },
                { label: 'Active', value: 'ACTIVE' },
                { label: 'Rejected', value: 'REJECTED' },
                { label: 'Completed', value: 'COMPLETED' },
              ]}
              value={selectedKeys}
              onChange={(values) => setSelectedKeys(values)}
            />
            <div style={{ marginTop: 8 }}>
              <Button
                onClick={() => confirm()}
                style={{ marginRight: 8 }}
                type="primary"
                className="text-white bg-blue-500"
              >
                OK
              </Button>
              <Button onClick={() => clearFilters()}>Reset</Button>
            </div>
          </div>
        ),
        onFilter: (value, record) => record.status === value,
  
      },
      
  //     {
  //       title: t("Time Duration"),
  //      dataIndex: ["jobId","hoursReqd"],
  // render: (text) => <span>{text} hours</span>,
  //       filters: [
  //         { text: '1-3 hours', value: '1-3' },
  //         { text: '4-7 hours', value: '4-7' },
  //         { text: '8-10 hours', value: '8-10' },
  //         { text: '11-12 hours', value: '11-12' },
  //       ],
  //       onFilter: (value, record) => {
  //         const [min, max] = value.split('-').map(Number);
  //         return record.hoursReqd >= min && record.hoursReqd <= max;
  //       },
  //       filterMultiple: false,
  //       filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
  //         <div style={{ padding: 8 }} className="flex flex-col">
  //           <Radio.Group
  //           className="flex flex-col p-2"
  //             options={[
  //               { label: '1-3 hours', value: '1-3' },
  //               { label: '4-7 hours', value: '4-7' },
  //               { label: '8-10 hours', value: '8-10' },
  //               { label: '11-12 hours', value: '11-12' },
  //             ]}
  //             value={selectedKeys[0]}
  //             onChange={(e) => setSelectedKeys([e.target.value])}
  //             verticle={true}
  //           />
  //           <div style={{ marginTop: 8 }}>
  //             <Button
  //               onClick={() => confirm()}
  //               style={{ marginRight: 8 }}
  //               type="primary"
  //               className="text-white bg-blue-500"
  //             >
  //               OK
  //             </Button>
  //             <Button onClick={() => clearFilters()}>Reset</Button>
  //           </div>
  //         </div>
  //       ),
  //     }, 
      {
        title: t("Chat"),
        render: (text, record) => (
          <Button onClick={()=>HandleChat(record)}>
            <div className="flex items-center gap-2">
              <p>Chat</p><FaRocketchat />
            </div>
          </Button>
        ),
      },
      {
        title: t("Complaint to admin"),
        render: (text, record) => (
          <Button onClick={()=>handleOpenModal(record)}>Complaint to admin</Button>
        ),
      },
  ];
  return (
    <div style={{ overflow: "auto" }}>
         <ComplaintsAdminModal  modalVisible={modalVisible} handleModalCancel={handleModalCancel} record={record} setModalVisible={setModalVisible} complaintType={"client"}/>

    {
      completeJobs?.length === 0 ?  <div className="flex justify-center items-center">
      <div>
      <img src={Emptyorder} style={{
        height:"150px"
      }}/>
      <p className="text-center text-xl">Orders Not Found</p>
      </div>
    
    
    </div>   : <Table columns={columns} dataSource={completeJobs} />

    }
   
  </div>
  );
};
export default Completed;

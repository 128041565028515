import React, {useCallback, useEffect, useState} from "react";
import {Form, Input, Button, Select, Table, Tag, message, Tabs, Typography, Modal} from "antd";
import {useTranslation} from "react-i18next";
import axios from "axios";

const {Option} = Select;

// const { Option } = Select;
const Withdraw = () => {
    const {t} = useTranslation();
    const [bankDetails, setBankDetails] = useState({});
    const [isModelOpen, setIsModelOpen] = useState(false);

    const [form] = Form.useForm();

    const getWallet = useCallback(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/bankdetails`, {
                withCredentials: true,
            })
            .then((res) => {
                setBankDetails(res.data.bankDetail);
                form.setFieldsValue({
                    accountType: res.data.bankDetail.accountType,
                    beneficiaryName: res.data.bankDetail.beneficiaryName,
                    accountNumber: res.data.bankDetail.accountNumber,
                    bankName: res.data.bankDetail.bankName,
                    bankCode: res.data.bankDetail.bankCode,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }, [])

    useEffect(() => {
        getWallet()
    }, [getWallet]);

    const onFinish = (values) => {
        axios
            .post(`${process.env.REACT_APP_API_URL}/bankdetails`, values, {
                withCredentials: true,
            })
            .then((res) => {
                getWallet();
                setIsModelOpen(false);
                setBankDetails(res.data.bankDetail);
                form.setFieldsValue({
                    accountType: res.data.bankDetail.accountType,
                    beneficiaryName: res.data.bankDetail.beneficiaryName,
                    accountNumber: res.data.bankDetail.accountNumber,
                    bankName: res.data.bankDetail.bankName,
                    bankCode: res.data.bankDetail.bankCode,
                });
            })
            .catch((err) => {
                message.error(err?.response?.data?.msg || err.message);
            });
    };
    return (
        <>
            <div className="flex justify-center mb-4">
                <div className="bg-white p-8 rounded shadow-md lg:w-[50%]">
                    <div>
                        <Button className={'bg-blue-500 text-white float-right'}
                                onClick={() => setIsModelOpen(true)}>Edit</Button>
                        <div>
                            <h2 className="text-xl font-semibold mb-4">
                                Account type : <span className={'capitalize'}>{bankDetails?.accountType || '-'}</span>
                            </h2>
                            <h2 className="text-xl font-semibold mb-4">
                                Beneficiary Name : {bankDetails?.beneficiaryName || '-'}
                            </h2>
                            <h2 className="text-xl font-semibold mb-4">
                                Account Number : {bankDetails?.accountNumber || '-'}
                            </h2>
                            <h2 className="text-xl font-semibold mb-4">
                                Bank Name : {bankDetails?.bankName || '-'}
                            </h2>
                            <h2 className="text-xl font-semibold mb-4">
                                Bank code : {bankDetails?.bankCode || '-'}
                            </h2>
                        </div>
                    </div>
                    <Modal
                        width={800}
                        centered
                        open={isModelOpen}
                        onOk={() => form.submit()}
                        okType="default"
                        okText="Ok"
                        cancelText="Cancel"
                        onCancel={() => setIsModelOpen(false)}
                    >
                        <div className="flex space-y-6 flex-col ">
                            <div>
                                <p className="font-bold text-xl text-[#001529]">
                                    {t("Update Bank Details")}
                                </p>
                            </div>
                            <div className="">
                                <Form
                                    form={form}
                                    name="bankDetailsForm"
                                    onFinish={onFinish}
                                    initialValues={{}}
                                >
                                    <Form.Item
                                        label={t("Beneficiary Name")}
                                        name="beneficiaryName"
                                        rules={[
                                            {
                                                required: true,
                                                message: t("Please enter the beneficiary Name"),
                                            },
                                        ]}
                                    >
                                        <Input type="text"/>
                                    </Form.Item>
                                    <Form.Item
                                        label={t("Account type")}
                                        name="accountType"
                                        rules={[
                                            {
                                                required: true,
                                                message: t("Please select account type"),
                                            },
                                        ]}
                                    >
                                        <Select
                                            placeholder="Account type"
                                        >
                                            <Option value="saving">Saving</Option>
                                            <Option value="current">Current</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label={t("Account Number")}
                                        name="accountNumber"
                                        rules={[
                                            {
                                                required: true,
                                                message: t("Please enter the account number"),
                                            },
                                        ]}
                                    >
                                        <Input type="text"/>
                                    </Form.Item>
                                    <Form.Item
                                        label={t("Bank Name")}
                                        name="bankName"
                                        rules={[
                                            {
                                                required: true,
                                                message: t("Please enter the bank name"),
                                            },
                                        ]}
                                    >
                                        <Input type="text"/>
                                    </Form.Item>
                                    <Form.Item
                                        label={t("Bank code")}
                                        name="bankCode"
                                        rules={[
                                            {
                                                required: true,
                                                message: t("Please enter the bank code"),
                                            },
                                        ]}
                                    >
                                        <Input type="text"/>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        </>
    );
};

export default Withdraw;

import React, { useContext } from "react";
import {
  Button,
  Checkbox,
  Radio,
  Space,
  Table,
} from "antd";
import { useTranslation } from "react-i18next";
import { ClientContext } from "../../../../Context/ClientContext";
import TruncatedColumn from "../../../Professional/Orders/TruncatedColumn";
import Emptyorder from "../../../../images/empty.png";
import NotOrderDetailButton from "../NotOrderDetailButton";

const Posted = () => {
  const { t } = useTranslation();
  const { notstartedJobs } = useContext(ClientContext);
  // const HandleChat = (record) => {
  //   if (!User) {
  //     message.error("Please login to your account first");
  //   } else {
  //     const orderNumberExists = userList.some(
  //       (user) => user.orderNumber === record.orderNumber
  //     );
  //     if (orderNumberExists) {
  //       navigate("/app/client/message");
  //     } else {
  //       setUserList([record, ...userList]);
  //       navigate("/app/client/message");
  //     }
  //   }
  // };
  notstartedJobs.sort((job1, job2) => {
    const date1 = new Date(job1.createdAt).toLocaleString();
    const date2 = new Date(job2.createdAt).toLocaleString();
    
    return new Date(date2) - new Date(date1);
  });
  const columns = [
    {
      title: t("Action"),
      render: (text, record) => (
        <Space size="middle">
          <NotOrderDetailButton record={record} />
          {/* <Button className="bg-red-500 text-white">Delete</Button> */}
        </Space>
      ),
    },
    {
      title: t("Price"),
      dataIndex: "price",
      render: (text, record) => (
        <span>${record.price}</span>
      ),
      width:"15%"
    },
    {
      title: t("Title"),
      dataIndex: 'title',
      width: "15%",
    },

    {
      title: t("Description"),
      dataIndex: "description",
      render: (text) => <TruncatedColumn text={text} />,
    },
    {
      title: t("Priority"),
      dataIndex: "priority",
      filters: [
        {
          text: t("HIGH"),
          value: "HIGH",
        },
        {
          text: t("MEDIUM"),
          value: "MEDIUM",
        },
        {
          text: t("LOW"),
          value: "LOW",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) =>
        record &&
        record.jobId &&
        record.priority &&
        record.priority.startsWith(value),
      width: "15%",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8, height: 150, overflow: "auto" }}>
          <div className="flex flex-col">
            <Checkbox.Group
              className="flex flex-col"
              options={[
                { label: t("HIGH"), value: "HIGH" },
                { label: t("MEDIUM"), value: "MEDIUM" },
                { label: t("LOW"), value: "LOW" },
              ]}
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e)}
              vertical
            />
          </div>

          <div style={{ marginTop: 8 }}>
            <Button
              onClick={() => confirm()}
              style={{ marginRight: 8 }}
              type="primary"
              className="text-white bg-blue-500"
            >
              OK
            </Button>
            <Button onClick={() => clearFilters()}>Reset</Button>
          </div>
        </div>
      ),
    },
   

    //  {
    //       title: t("Time Duration"),
    //       dataIndex: ["jobId","hoursReqd"],
    //       render: (text) => <span>{text} hours</span>,
    //       filters: [
    //         { text: '0-2 hours', value: '0-2' },
    //         { text: '2-4 hours', value: '2-4' },
    //         { text: '4-6 hours', value: '4-6' },
    //         { text: '6-8 hours', value: '6-8' },
    //       ],
    //       onFilter: (value, record) => {
    //         const [min, max] = value.split('-').map(Number);
    //         return record.hoursReqd >= min && record.hoursReqd <= max;
    //       },
    //       filterMultiple: false,
    //     },
    // {
    //   title: t("Time Duration"),
    //   dataIndex: "hoursReqd",
    //   render: (text) => <span>{text} hours</span>,
    //   filters: [
    //     { text: '1-3 hours', value: '1-3' },
    // { text: '4-7 hours', value: '4-7' },
    // { text: '8-10 hours', value: '8-10' },
    // { text: '11-12 hours', value: '11-12' },
    //   ],
    //   onFilter: (value, record) => {
    //     const [min, max] = value.split("-").map(Number);
    //     return record.hoursReqd >= min && record.hoursReqd <= max;
    //   },
    //   filterMultiple: false,
    //   filterDropdown: ({
    //     setSelectedKeys,
    //     selectedKeys,
    //     confirm,
    //     clearFilters,
    //   }) => (
    //     <div style={{ padding: 8 }} className="flex flex-col">
    //       <Radio.Group
    //         className="flex flex-col p-2"
    //         options={[
    //           { label: '1-3 hours', value: '1-3' },
    // { label: '4-7 hours', value: '4-7' },
    // { label: '8-10 hours', value: '8-10' },
    // { label: '11-12 hours', value: '11-12' },
    //         ]}
    //         value={selectedKeys[0]}
    //         onChange={(e) => setSelectedKeys([e.target.value])}
    //         verticle={true}
    //       />
    //       <div style={{ marginTop: 8 }}>
    //         <Button
    //           onClick={() => confirm()}
    //           style={{ marginRight: 8 }}
    //           type="primary"
    //           className="text-white bg-blue-500"
    //         >
    //           OK
    //         </Button>
    //         <Button onClick={() => clearFilters()}>Reset</Button>
    //       </div>
    //     </div>
    //   ),
    // },
    // {
    //   title: t("Chat"),
    //   render: (text, record) => (
    //     <Button onClick={() => HandleChat(record)}>
    //       <div className="flex items-center gap-2">
    //         <p>Chat</p>
    //         <FaRocketchat />
    //       </div>
    //     </Button>
    //   ),
    // },
  ];
  return (
    <div style={{ overflow: "auto" }}>
      {notstartedJobs.length === 0 ? (
        <div className="flex justify-center items-center">
          <div>
            <img
              src={Emptyorder}
              alt="empty"
              style={{
                height: "150px",
              }}
            />
            <p className="text-center text-xl">Orders Not Found</p>
          </div>
        </div>
      ) : (
        <Table columns={columns} dataSource={notstartedJobs} />
      )}
    </div>
  );
};
export default Posted;

import React from "react";
import { Tabs } from "antd";
import Pending from "./ComplaintsTables/Freelancer/Pending";
import Completed from "./ComplaintsTables/Freelancer/Completed";
import Rejected from "./ComplaintsTables/Freelancer/Rejected";


const { TabPane } = Tabs;

const ComplaintsClient = () => {
  return (
    <Tabs>
      <TabPane>
        <Tabs defaultActiveKey="pending">
          <TabPane tab="Pending" key="pending">
            <div className="bg-yellow-500 rounded-md p-3">
              <p className="text-white font-medium text-lg">Pending Requests</p>
            </div>
            <Pending />
          </TabPane>
          <TabPane tab="Completed" key="completed">
            <div className="bg-green-500 rounded-md p-3">
              <p className="text-white font-medium text-lg">Completed Orders</p>
            </div>
            <Completed/>
          </TabPane>
          <TabPane tab="Rejected" key="rejected">
            <div className="bg-red-500 rounded-md p-3">
              <p className="text-white font-medium text-lg">
                Rejected Requests
              </p>
            </div>
            <Rejected/>
          </TabPane>
        </Tabs>
      </TabPane>
      
    </Tabs>
  );
};

export default ComplaintsClient;

import React, { useContext } from "react";
import { Tag, Table } from "antd";
import { Link, useParams } from "react-router-dom";
import { SupportContext } from "../../Context/AdminContext";
import { IoArrowBackSharp } from "react-icons/io5";

const Order = () => {
  const { id } = useParams();
  const {  allJobsSupport ,allUsers} = useContext(SupportContext);
   const order = allJobsSupport?.find((order) => order._id === id);
  const findjobpostername = allUsers?.find((alluser)=> alluser?._id === order?.jobPoster)
  const findFreelancerName = allUsers?.find((alluser) => alluser?._id === order?.userId);
//   const userIdsFromJobs = allJobsSupport.map(job => job.userId);
const userId = findFreelancerName?._id;

const jobsForUser = allJobsSupport?.find((job) => job.userId === userId);
// const usersInJobs = allUsers.filter(user => userIdsFromJobs.includes(user._id));

  if (!order) {
    return <p>Loading...</p>; 
  }

  if (!order.jobId) {
    return <p>Loading job details...</p>;
  }

  const columns = [
    { title: "Field", dataIndex: "field" },
    { title: "Value", dataIndex: "value" },
  ];

  const data = [
    { key: "1", field: "OrderId", value: `#${order.jobId.jobId}` },
    { key: "2", field: "Title", value: order.jobId.title },
    { key: "3", field: "Description", value: order.jobId.description },
    { key: "4", field: "Price", value: `$${order.jobId.price}` },
    { key: "5", field: "Client", value: `${findjobpostername.name}` },
    { key: "6", field: "Freelancer", value: `${findFreelancerName.name}` },
    // { key: "7", field: "Freelancer Budget", value: `$${jobsForUser.price}` },
    { key: "7", field: "Order Posted on", value: `   ${new Date(findjobpostername.createdAt).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short',
    })}` },

    { key: "8", field: "Priority", value: order.jobId.priority },
    {
      key: "9",
      field: "Status",
      value: (
        <span className="flex items-center gap-2">
          {order.status === "IN_REVIEW" && (
            <Tag className="bg-yellow-500 text-white font-bold">Under Review</Tag>
          )}
          {order.status === "ACTIVE" && (
            <Tag className="bg-blue-500 text-white font-bold">Active</Tag>
          )}
          {order.status === "REJECTED" && (
            <Tag className="bg-red-500 text-white font-bold">Rejected</Tag>
          )}
          {order.status === "COMPLETED" && (
            <Tag className="bg-green-500 text-white font-bold">Completed</Tag>
          )}
        </span>
      ),
    },
  ];

  return (
    <>      <Link to={"/support/orders"} className="hover:text-black"><IoArrowBackSharp className="cursor-pointer text-xl"/></Link>  
    <div className=" lg:mx-4 lg:p-6 my-5 mx-2 rounded-xl p-2 md:my-4 md:mx-2 md:p-4">
      <div className="flex justify-center items-center">
        <div className="p-4 bg-slate-200 text-gray-900 lg:w-[60%] border rounded-md shadow-md">
          <h2 className="text-lg font-semibold text-center mb-3">Order Detail</h2>
          <Table columns={columns} dataSource={data} pagination={false} />
        </div>
      </div>
    </div>
    </>

  );
};

export default Order;

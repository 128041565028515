import React, {useCallback, useEffect, useState} from "react";
import {Button, Input, Modal, Select, Spin, Table, Tabs, Tag, Typography} from "antd";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {PayoutStatus} from "../../utils/constants";
import {DeleteOutlined} from "@ant-design/icons";

const {Option} = Select;

const Complaints = () => {
    const {t} = useTranslation();
    const [payouts, setPayouts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [payout, setPayout] = useState(null);
    const getPayouts = useCallback(() => {
        setLoading(true);
        axios
            .get(`${process.env.REACT_APP_API_URL}/payout?isAdmin=true`, {
                withCredentials: true,
            })
            .then((res) => {
                setLoading(false);
                setPayouts(res.data.payouts);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    }, []);

    useEffect(() => {
        getPayouts()
    }, [getPayouts]);

    const columns = [
        {
            title: t('Freelancer Name'),
            key: 'requestUser',
            dataIndex: ['requestUser', 'name'],
        },
        {
            title: t("Request Date"),
            dataIndex: "createdAt",
            render: (text) => <p>{
                new Date(text).toLocaleDateString('en-US', {
                    hour: 'numeric',
                    minute: '2-digit',
                    hour12: true
                })
            }</p>,
            sorter: (a, b) => {
                const date1 = new Date(a.createdAt).toLocaleString();
                const date2 = new Date(b.createdAt).toLocaleString();

                return new Date(date2) - new Date(date1);
            },
            defaultSortOrder: null,
        },
        {
            title: t("Amount"),
            dataIndex: "amount",
            render: (text) => <p>${text}</p>
        },
        {
            title: t("Status"),
            dataIndex: "status",
            render: (text) => (
                <span className="flex items-center gap-2">
                    {text === "IN_PROGRESS" && (
                        <Tag className="bg-yellow-500 text-white font-bold">
                            In progress
                        </Tag>
                    )}
                    {text === "IN_QUEUE" && (
                        <Tag className="bg-yellow-500 text-white font-bold">
                            In Queue
                        </Tag>
                    )}
                    {text === "PENDING" && (
                        <Tag className="bg-yellow-500 text-white font-bold">
                            Pending
                        </Tag>
                    )}
                    {text === "ERROR" && (
                        <Tag className="bg-red-500 text-white font-bold">ERROR</Tag>
                    )}
                    {text === "DECLINED" && (
                        <Tag className="bg-red-500 text-white font-bold">Declined</Tag>
                    )}
                    {text === "COMPLETED" && (
                        <Tag className="bg-green-500 text-white font-bold">Completed</Tag>
                    )}
                </span>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <>
                    <Button type="primary" className="text-white bg-blue-500"
                            onClick={() => {
                                setPayout(record);
                                setShowDetails(true)
                            }}>View</Button>
                </>
            ),
        },
    ];

    function tabContent() {
        return (
            <Spin spinning={loading}>
                <Table columns={columns} dataSource={payouts}/>
            </Spin>
        )
    }

    const handleOk = () => {
        axios
            .put(`${process.env.REACT_APP_API_URL}/payout`, {
                status: payout.status,
                requiredDetails: payout.requiredDetails,
                id: payout._id
            }, {
                withCredentials: true,
            })
            .then((res) => {
                setShowDetails(false);
                getPayouts();
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleCancel = () => {
        setShowDetails(false);
    };

    return (
        <>
            <Tabs items={[
                {
                    key: 1,
                    label: 'All',
                    children: tabContent()
                },
                {
                    key: 2,
                    label: 'Pending transactions',
                    children: tabContent()
                }
            ]}/>
            <Modal
                width={800}
                centered
                open={showDetails}
                onOk={handleOk}
                okType="default"
                okText="Save"
                cancelText="Cancel"
                onCancel={handleCancel}
            >
                <div className="flex space-y-6 flex-col ">
                    <div>
                        <p className="font-bold text-xl text-[#001529]">
                            {t("Payout Details")}
                        </p>
                    </div>
                    <div className="">
                        <p>{t("Freelancer Name")} : {payout?.requestUser?.name}</p>
                        <p>{t("Request date")} : {new Date(payout?.createdAt).toLocaleDateString('en-US', {
                            hour: 'numeric',
                            minute: '2-digit',
                            hour12: true
                        })}</p>
                        <p>{t("Amount")} : ${payout?.amount}</p>
                        <Typography.Title className={'mt-2'} level={5}>Bank Details</Typography.Title>
                        <p>{t("Account type")} : <span
                            className={'capitalize'}>{payout?.bankDetails?.accountType || '-'}</span>
                        </p>
                        <p>{t("Beneficiary Name")} : {payout?.bankDetails?.beneficiaryName || '-'}</p>
                        <p>{t("Account Number")} : {payout?.bankDetails?.accountNumber || '-'}</p>
                        <p>{t("Bank Name")} : {payout?.bankDetails?.bankName || '-'}</p>
                        <p>{t("Bank code")} : {payout?.bankDetails?.bankCode || '-'}</p>
                        <Typography.Title className={'mt-2'} level={5}>Status</Typography.Title>
                        <Select
                            id="priority"
                            value={payout?.status}
                            onChange={(value) => setPayout(prevState => ({...prevState, status: value}))}
                            placeholder={t("Please payout status")}
                        >
                            {Object.keys(PayoutStatus).map(t => (
                                <Option value={t}>{PayoutStatus[t]}</Option>
                            ))}
                        </Select>

                        {(payout?.status !== 'ERROR' || payout?.status === 'DECLINED') && <>
                            <Typography.Title level={5}>
                                Enter requirements to complete this payment.
                                <Button className={'ms-2 text-white bg-blue-500'} type={'primary'} size={'small'}
                                        onClick={() => {
                                            setPayout(prev => {
                                                let required = [...prev.requiredDetails];
                                                required.push({
                                                    name: '',
                                                    value: ''
                                                })
                                                return {
                                                    ...prev,
                                                    requiredDetails: required
                                                }
                                            })
                                        }}
                                >+</Button>
                            </Typography.Title>
                            {payout?.requiredDetails.map((item, index) => (
                                <div className={'flex mb-2'}>
                                    <Input value={item.name} placeholder={"Enter name"} rootClassName={'me-2 w-48'}
                                           onChange={(e) => {
                                               setPayout(prev => {
                                                   let required = [...prev.requiredDetails];
                                                   required[index]['name'] = e.target.value;
                                                   return {
                                                       ...prev,
                                                       requiredDetails: required
                                                   }
                                               })
                                           }}></Input>
                                    <Input value={item.value} placeholder={"Description"} onChange={(e) => {
                                        setPayout(prev => {
                                            let required = [...prev.requiredDetails];
                                            required[index]['value'] = e.target.value;
                                            return {
                                                ...prev,
                                                requiredDetails: required
                                            }
                                        })
                                    }}></Input>
                                    <Button className={'border-red-500 ms-2 flex items-center'} onClick={() => {
                                        setPayout(prev => {
                                            let required = [...prev.requiredDetails];
                                            required.splice(index, 1);
                                            return {
                                                ...prev,
                                                requiredDetails: required
                                            }
                                        })
                                    }}><DeleteOutlined/></Button>
                                </div>
                            ))}
                        </>}

                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Complaints;

import { useTranslation } from "react-i18next";
import { Radio } from "antd";
import { TranslationOutlined } from "@ant-design/icons";

const LangSwitch = () => {
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <>
      <div className="space-x-3">
        {/* <span style={{ marginRight: 16 }}>Change locale of components:</span> */}
        <TranslationOutlined style={{ color: "#1470f1", fontSize: 25 }} />
        <Radio.Group optionType="button" buttonStyle="outline">
          <Radio.Button key="en" onClick={() => changeLanguage("en")}>
            English
          </Radio.Button>
          <Radio.Button key="es" onClick={() => changeLanguage("es")}>
            Espanol
          </Radio.Button>
        </Radio.Group>
      </div>
    </>
  );
};

export default LangSwitch;

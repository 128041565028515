import React from "react";
import Header from "./Header";
import Hero from "./Hero";
import Footer1 from "./Footer1";
import Section from "./Section";
import Process from "./Process/Process";
import { useNavigate } from "react-router-dom";

const Landing = () => {
  const navigate = useNavigate()
 
  return (
    <div className="bg-[#001529]  ">
      <Header />
      <Hero />
      <Process />
      <Section />
      <Footer1 />
    </div>
  );
};

export default Landing;

import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { UserOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Modal, Form, Input, Tag, Divider } from "antd";
import { useTranslation } from "react-i18next";
import { SupportContext } from "../../Context/AdminContext";
import ChatButton from "../Chat/ChatButton";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { MdOutlineVerified } from "react-icons/md";
import { ImCancelCircle } from "react-icons/im";

const UserDetails = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { allUsers, allTags } = useContext(SupportContext);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const singleuser = allUsers?.find((data) => data._id === id);

  if (!singleuser) {
    return (
      <div className="flex justify-center items-center h-[100vh] ">
        <h1 className="text-2xl">User not found</h1>
      </div>
    );
  }

  const { name, email, tags, emailVerified, createdAt } = singleuser;
  console.log(tags, "tagss");
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = (values) => {
    console.log("Received values:", values);
    setIsModalVisible(false);
  };

  return (
    <div>
      <div className="container mx-auto md:p-5">
        <div className="lg:w-full space-y-6 lg:grid lg:gap-6 lg:grid-cols-2">
          <div className="about bg-slate-200 p-6 shadow-sm rounded-md">
            <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
              <span className="text-2xl">{t("Personal Information")}</span>
              <span className="">
                <UserOutlined size={30} />
              </span>
            </div>
            <Divider />
            <div className="grid text-gray-700 text-base">
              <div className="grid md:grid-cols-2">
                <div className="px-4 py-2 font-semibold">Name</div>
                <div className="px-4 py-2">{name}</div>
              </div>
              <div className="grid md:grid-cols-2">
                <div className="px-4 py-2 font-semibold">Email ID</div>
                <div className="px-4 py-2">
                  <a className="text-blue-800" href={`mailto:${email}`}>
                    {email}
                  </a>
                </div>
              </div>
              <div className="grid md:grid-cols-2">
                <div className="px-4 py-2 font-semibold">Email verfied</div>
                <div className="px-4 py-2 flex items-center">
                  {" "}
                  {emailVerified ? (
                    <>
                      Verified <RiVerifiedBadgeFill className="text-blue-500" />
                    </>
                  ) : (
                    "Not verified"
                  )}
                </div>
              </div>
              <div className="grid md:grid-cols-2">
                <div className="px-4 py-2 font-semibold">Account Created </div>
                <div className="px-4 py-2">
                  {" "}
                  {createdAt && (
                    <>
                      Created at{" "}
                      {new Date(createdAt).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                        timeZoneName: "short",
                      })}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="personal bg-slate-200 p-6 shadow-sm rounded-md">
            <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
              <span className="text-2xl">{t("Professional Information")}</span>
              <span className="">
                <UserOutlined size={20} />
              </span>
              <Button type="link" icon={<EditOutlined />} onClick={showModal}>
                {t("Edit Profile")}
              </Button>
            </div>
            <Divider />
            <div className="grid text-gray-700 text-base">
              <div className="px-4 pt-4 pb-2 text-lg font-semibold">
                {t("Bio")}
              </div>
              <div className="px-4 pb-2">{singleuser.bio}</div>
              <div className="grid md:grid-cols-2">
                <div className="px-4 py-2 font-semibold">
                  {t("Order Completed:")}
                </div>
                <div className="px-4 py-2">{singleuser.jobsCompleted}</div>
              </div>
              <div className="grid md:grid-cols-2">
                <div className="px-4 py-2 font-semibold">
                  {t("Total Earning:")}
                </div>
                <div className="px-4 py-2">${singleuser.moneyEarned}</div>
              </div>
              <div className="grid md:grid-cols-2">
                <div className="px-4 py-2 font-semibold">
                  {t("Email Verfied:")}
                </div>
                <div className="px-4 py-2 flex items-center">
                  {singleuser.emailVerified ? (
                    <>
                      <span>Verified</span>
                      <MdOutlineVerified className="ml-2 text-blue-500" />
                    </>
                  ) : (
                    <>
                      <span>Unverified</span>
                      <ImCancelCircle className="ml-2 text-red-500" />
                    </>
                  )}
                </div>
              </div>
              <div className="grid md:grid-cols-2">
                <div className="px-4 pt-2 pb-2 font-semibold">
                  {t("Tags Working On:")}
                </div>
                <div className="px-4 pb-2 flex flex-wrap">
                  {singleuser.tagsWorkingOn.length > 0 ? (
                    singleuser.tagsWorkingOn.map((tagId) => {
                      const tag = allTags.find((tag) => tag._id === tagId);

                      return (
                        <Tag key={tagId} className="p-2 m-1">
                          {tag ? tag.name : "Unknown Tag"}
                        </Tag>
                      );
                    })
                  ) : (
                    <span>No tag added yet</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ChatButton />

      <Modal
        title={t("Edit Profile")}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          onFinish={onFinish}
          initialValues={{
            name: name,
            email: email,
          }}
        >
          <Form.Item
            name="name"
            label={t("Name")}
            rules={[{ required: true, message: t("Please enter your name") }]}
          >
            <Input prefix={<UserOutlined />} />
          </Form.Item>
          <Form.Item
            name="email"
            label={t("Email")}
            rules={[
              { required: true, message: t("Please enter your email") },
              { type: "email", message: t("Please enter a valid email") },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item className="flex justify-center">
            <Button htmlType="submit">{t("Update")}</Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UserDetails;

import React, { useContext, useEffect, useState } from 'react';
import { Table, Button, Spin, message, Modal } from 'antd';
import { SupportContext } from '../../../../Context/AdminContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Pending = () => {
  const { allUsers,allJobsSupport,allTags, pendingLoading, setPendingLoading,clientData,setClientData} = useContext(SupportContext);
  const [fullComplaintModalVisible, setFullComplaintModalVisible] = useState(false);
  const [selectedComplaint, setSelectedComplaint] = useState('');
  const navigate = useNavigate();
  const getPendingClientRequest = () => {
    setPendingLoading(true);
    axios.get(`${process.env.REACT_APP_API_URL}/admin/complaint/client/pending`)
      .then(res => {
        setClientData((prevData) => ({
          ...prevData,
          pendingReq: res.data?.pendingComplaints, 
        }));
        setPendingLoading(false);
      })
      .catch(err => {
        console.log(err);
        setPendingLoading(false);
      });
  };

  useEffect(() => {
    getPendingClientRequest();
  }, []);

  const handleAccept = (id) => {
    setPendingLoading(true);
    axios.post(`${process.env.REACT_APP_API_URL}/admin/complaint/resolve/${id}`, {}, { withCredentials: true })
      .then(res => {
        message.success("Complaint accepted");
        getPendingClientRequest();
      })
      .catch(err => console.log(err))
      .finally(() => setPendingLoading(false));
  };

  const handleDelete = (id) => {
    setPendingLoading(true);
    axios.post(`${process.env.REACT_APP_API_URL}/admin/complaint/reject/${id}`, {}, { withCredentials: true })
      .then(res => {
        message.error("Complaint rejected");
        getPendingClientRequest();
      })
      .catch(err => console.log(err))
      .finally(() => setPendingLoading(false));
  };

  const handleShowFullComplaint = (complaint) => {
    setSelectedComplaint(complaint);
    setFullComplaintModalVisible(true);
  };

  const columns = [
    {
      title: 'Client Name',
      dataIndex: 'clientName',
      key: 'clientName',
    },
    {
      title: 'Freelancer Name',
      dataIndex: 'freelancerName',
      key: 'freelancerName',
    },
 
   
    {
      title: 'Status',
      dataIndex: 'jobStatus',
      key: 'jobStatus',
    },

    {
      title: 'Complaint',
      dataIndex: 'complaint',
      key: 'Complaint',
      render: (complaint) => (
        <div>
          {complaint.length > 30 ? (
            <span>
              {complaint.substr(0, 30)}
              <Button type="link" onClick={() => handleShowFullComplaint(complaint)}>...More</Button>
            </span>
          ) : (
            <span>{complaint}</span>
          )}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Button type="primary" className="text-white bg-blue-500" onClick={() => handleAccept(record.key)}>Resolve</Button>
          <Button type="danger" className="text-white bg-red-500 m-2" onClick={() => handleDelete(record.key)}>Reject</Button>
          <Button type="primary" className="text-white bg-blue-500" onClick={() =>navigate(`/support/complaints/client/pending/${record.key}`)}>Details</Button>
        </span>
      ),
    },
  ];

  const data = [...clientData?.pendingReq]
  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
  .map((item) => {
    const user = allUsers?.find((user) => user?._id === item?.clientId);
    const freelancer = allUsers?.find((user) => user?._id === item?.freelancerId);
    const price = allJobsSupport?.find((p) => item?.jobId === p?._id);
    const area = allJobsSupport?.find((job) => item?.jobId === job._id);
    const area1 = allTags.find((tag) => area?.jobId.tagAssociated === tag?._id);
    return {
      key: item._id,
      clientName: user?.name,
      freelancerName: freelancer?.name,
      area: area1?.name,
      price: `$${price.price}`,
      jobStatus: item.status,
      complaint: item.complaint,
    };
  });


  return (
    <>
      <Spin spinning={pendingLoading}>
        <Table columns={columns} dataSource={data} />
      </Spin>
      <Modal
        title="Complaint"
        visible={fullComplaintModalVisible}
        onCancel={() => setFullComplaintModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setFullComplaintModalVisible(false)}>Close</Button>
        ]}
      >
        <p>{selectedComplaint}</p>
      </Modal>
    </>
  );
};

export default Pending;

import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const MonthlyUser = () => {
  const userData = [
    { month: 'January', newUsers: 90 },
    { month: 'February', newUsers: 150 },
    { month: 'March', newUsers: 8 },
    { month: 'April', newUsers: 120 },
    { month: 'May', newUsers: 20 },
    { month: 'June', newUsers: 100 },
    { month: 'July', newUsers: 40 },
    { month: 'Aug', newUsers: 130 },
    { month: 'Sept', newUsers: 20 },
    { month: 'Oct', newUsers: 50 },
    { month: 'Nov', newUsers: 180 },
    { month: 'Dec', newUsers: 10 },

  ];

  return (
    <ResponsiveContainer height={400} className="mt-20 w-full">
      <BarChart data={userData} >
        <XAxis dataKey="month" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="newUsers" fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default MonthlyUser;

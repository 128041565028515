import React, { useContext, useState } from "react";
import { Button, Drawer, Space, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { ClientContext } from "../../Context/ClientContext";

const UserInfo = ({ User }) => {
  console.log(User, "user");
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { userTag } = useContext(ClientContext);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const filteredTags = userTag.filter((tag) =>
    User.tagsWorkingOn.includes(tag._id)
  );

  return (
    <>
      <Space className="relative top-[45px]">
        <Button type="default" onClick={showDrawer}>
          {t("Professional's Information")}
        </Button>
      </Space>
      <Drawer
        title="Professional Info"
        placement="right"
        width={500}
        onClose={onClose}
        open={open}
      >
        <p>{t("User Info")}</p>

        <h2>User Name : {User?.name}</h2>
        <h2>Email Id : {User?.email}</h2>
        <h2>Total Earning : ${User?.moneyEarned}</h2>
        <h2>Job Completed : {User?.jobsCompleted}</h2>
        <h2>
          User Working On:
          {filteredTags.length > 0 ? (
            filteredTags.map((tag) => <Tag className="m-1">{tag.name}</Tag>)
          ) : (
            <span>User not working on any tag.</span>
          )}
        </h2>
      </Drawer>
    </>
  );
};
export default UserInfo;

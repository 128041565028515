import React, { useContext, useEffect, useState } from "react";
import {
  Steps,
  Button,
  message,
  Form,
  Input,
  InputNumber,
  Select,
  Spin,
} from "antd";
import { useTranslation } from "react-i18next";
import CreatableSelect from "react-select/creatable";
import { useForm } from "antd/es/form/Form";
import { UserContext } from "../../../Context/UserContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AsClientRegister from "../AsClientRegister";
import { ClientContext } from "../../../Context/ClientContext";
import WompiTestTransaction from "./Test";

const { Step } = Steps;
const { Option } = Select;

const PostProject = () => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedTags, setSelectedTags] = useState([]);
  const [orderDetailed, setOrderDetailed] = useState({});
  const [loading, setLoading] = useState(false);
  const [singletaguser, setSingleTaguser] = useState(null);
  const [selectval, setSelectVal] = useState(null);
  const [singleTag, setSingleTag] = useState([]);
  const { fullprofile } = useContext(UserContext);
  const { userTag, setUserTags } = useContext(ClientContext);
  const navigate = useNavigate();
  const [form] = useForm();
  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleNextFirst = async () => {
    try {
      await form.validateFields();
      if (selectedTags.length === 0) {
        throw new Error(t("Please select or create at least one tag"));
      }

      const formData = form.getFieldsValue();

      const findnetag = userTag?.filter(
        (val) => val.name === selectedTags[0]?.label
      );
      setSingleTag(findnetag);

      // Set order detailed
      setOrderDetailed({ ...formData });
      // Proceed to the next step
      handleNext();
    } catch (errorInfo) {
      // Handle validation errors
      console.log("Validation failed:", errorInfo);
    }
  };

  const handleSelectChange = (value) => {
    const findtag = userTag.find((tag) => tag._id === value?.value);
    setSelectVal(value);
    setSingleTaguser(findtag);

    if (Array.isArray(value)) {
      setSelectedTags(value);
    } else if (value) {
      setSelectedTags([value]);
    } else {
      setSelectedTags(null);
    }
  };

  useEffect(() => {
    const userProfile = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/tags`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });
        const data = await response.json();
        if (data.success) {
          setUserTags(data.tags);
        } else {
          console.log("Error: ", data.msg);
        }
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    };

    userProfile();
  }, [loading]);

  const disabledFutureDates = (current) => {
    return current && current > new Date();
  };

  const HandleaddTag = (newTagName) => {
    if (newTagName) {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/tags/by-name`,
          { name: newTagName },
          { withCredentials: true }
        )
        .then((res) => {
          setLoading(false);
          message.success("Tag added");
          setSelectVal({ value: res.data._id, label: res.data.name });
          setSingleTaguser(null);
          setSelectedTags([{ value: res.data._id, label: res.data.name }]);
        })
        .catch((error) => {
          setLoading(false);
          message.error(error.response.data.msg);
        });
    }
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };
  const handleFinish = async () => {
    try {
      setLoading(true);

      const { area, ...orderDataWithoutArea } = orderDetailed;

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/jobs`,
        { ...orderDataWithoutArea, tagAssociated: singleTag[0]?._id },
        {
          withCredentials: true,
        }
      );

      if (response.data.success) {
        message.success("Order placed successfully!");
        navigate("/app/client/orders");
        setLoading(false);
      } else {
        message.error("Failed to place the order. Please try again.");
        navigate("/app/client/orders");

        setLoading(false);
      }
    } catch (error) {
      console.error("Error while sending order data:", error);
      message.error("Failed to place the order. Please try again.");
      setLoading(false);
    }
  };

  const steps = [
    {
      title: t("Order Details"),
      content: (
        <div>
          <Form
            form={form}
            className="w-full max-w-lg mx-auto  shadow-md p-4"
            layout="vertical"
          >
            <div className="flex flex-col">
              <label htmlFor="title">{t("Title")}</label>
              <Form.Item
                name="title"
                rules={[
                  { required: true, message: t("Please enter a title") },
                  {
                    max: 50,
                    message: t("Title must not exceed 50 characters"),
                  },
                ]}
              >
                <Input id="title" placeholder={t("Enter your title")} />
              </Form.Item>
            </div>
            <Spin spinning={loading}>
              <div className="flex flex-col">
                <label htmlFor="area">{t("Area")}</label>
                <Form.Item
                  name="area"
                  className="w-full"
                  rules={[
                    {
                      required: true,
                      message: t("Please select or create an area"),
                    },
                  ]}
                >
                  <CreatableSelect
                    value={selectedTags}
                    onChange={handleSelectChange}
                    onCreateOption={(inputValue) => {
                      setSelectVal({ value: inputValue, label: inputValue });
                      HandleaddTag(inputValue);
                    }}
                    options={userTag
                      ?.filter((tag) => {
                        return !userTag.tags?.some(
                          (userTag) => userTag._id === tag._id
                        );
                      })
                      .map((user) => ({
                        value: user?._id,
                        label: user?.name,
                      }))
                      .sort((a, b) => a.label.localeCompare(b.label))}
                    placeholder="Select or type to add tags"
                    className="w-full md:w-[80%] m-1 _placeholder:text-red-800"
                    isClearable
                    formatCreateLabel={(inputValue) => (
                      <span>
                        Click enter to add this tag{" "}
                        <strong>{inputValue}</strong>
                      </span>
                    )}
                  />
                </Form.Item>
              </div>
            </Spin>
            <div className="flex justify-between ">
              <div className="flex  gap-10">
                <div className="flex flex-col">
                  <label htmlFor="price">{t("Price")}</label>
                  <Form.Item
                    name="price"
                    rules={[
                      {
                        required: true,
                        message: t(" Price is required"),
                      },
                      {
                        type: "number",
                        max: 50000,
                        message: "Price cannot be greater than 50000",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const maxPrice = getFieldValue("maxPrice");
                          if (value > maxPrice) {
                            return Promise.reject(
                              "Price should not be greater than Maximum Price"
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <InputNumber id="price" min={1} />
                  </Form.Item>
                </div>
               
              </div>
              <div className="flex flex-col">
              <label htmlFor="priority">{t("Priority")}</label>
              <Form.Item
                name="priority"
                rules={[
                  {
                    required: true,
                    message: t("Please select any one"),
                  },
                ]}
              >
                <Select
                  id="priority"
                  placeholder={t("Please select type of your order")}
                >
                  <Option value="HIGH">{t("HIGH")}</Option>
                  <Option value="MEDIUM">{t("MEDIUM")}</Option>
                  <Option value="LOW">{t("LOW")}</Option>
                </Select>
              </Form.Item>
            </div>
              {/* <div className="flex flex-col">
                <label htmlFor="hoursReqd">{t("Time Duration (hours)")}</label>
                <Form.Item
                  name="hoursReqd"
                  rules={[{ required: true, message: t("Time is required") }]}
                >
                  <InputNumber id="hoursReqd" min={0} />
                </Form.Item>
              </div> */}
            </div>

            
            <div className="flex flex-col">
              <label htmlFor="description">{t("Description")}</label>
              <Form.Item
                name="description"
                rules={[
                  {
                    required: true,
                    message: t("Order Description is required"),
                  },
                  {
                    max: 400,
                    min: 10,
                    message: t(
                      "Description must be in between 10-400 characters"
                    ),
                  },
                ]}
              >
                <Input.TextArea
                  id="description"
                  rows={3}
                  placeholder={t("Describe about the Order")}
                />
              </Form.Item>
            </div>

            <div className="flex justify-center">
              <Form.Item>
                <Button
                  style={{ backgroundColor: "Highlight" }}
                  type="primary"
                  onClick={handleNextFirst}
                >
                  {t("Next")}
                </Button>
                {/* <WompiTestTransaction currentStep={currentStep} singleTag={singleTag}  handleNextFirst={handleNextFirst} orderDetailed={orderDetailed}/> */}
              </Form.Item>
            </div>
          </Form>
        </div>
      ),
    },
   
    {
      title: t("Confirm Order"),
      content: (
        <div>
          <Spin
            spinning={loading}
            delay={500}
            size="large"
            tip="Posting project..."
          >
            <div className="mx-5">
              <p className="font-medium text-lg text-center">
                {t("Review your order details ")}here.
              </p>
              <div className="flex justify-center">
                <div className="about bg-white border-2 w-[50%] p-5 mb-7 shadow-sm rounded-md">
                  <div className="flex justify-center">
                    <div className="about  p-5 mb-7 shadow-sm rounded-md w-full max-w-lg">
                      <div className="flex flex-col  text-base gap-4">
                        <div className="flex md:flex-row justify-between">
                          <div className="font-semibold">{t("Price")}</div>
                          <div>
                            ${orderDetailed?.price}                          </div>
                        </div>
                        <div className="flex md:flex-row justify-between">
                          <div className="font-semibold">{t("Area")}</div>
                          <div>{orderDetailed?.area?.label}</div>
                        </div>
                        <div className="flex md:flex-row justify-between">
                          <div className="font-semibold">
                            {t("Description")}
                          </div>
                          <div>{orderDetailed?.description}</div>
                        </div>
                        {/* <div className="flex md:flex-row justify-between">
                          <div className="font-semibold">
                            {t("Time Duration")}
                          </div>
                          <div>{orderDetailed?.hoursReqd} Hours</div>
                        </div> */}
                        <div className="flex md:flex-row justify-between">
                          <div className="font-semibold">{t("Priority")}</div>
                          <div>{orderDetailed?.priority}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="my-3">
                <p className="text-center">
                  {t(
                    "*Note : Before confirming your order, review your details and payment amount."
                  )}
                </p>
              </div>
            </div>
          </Spin>
          <div className=" flex justify-center">
            <Button
              type="primary"
              style={{
                backgroundColor: "Highlight",

                margin: "0 8px",
              }}
              onClick={handlePrev}
            >
              {t("Previous")}
            </Button>
            {/* <Button
              style={{ backgroundColor: "Highlight" }}
              type="primary"
              onClick={handleFinish}
            >
              {t("Confirm Order")}
            </Button> */}
            <WompiTestTransaction
              currentStep={currentStep}
              singleTag={singleTag}
              handleNextFirst={handleNextFirst}
              orderDetailed={orderDetailed}
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="space-y-10">
      {fullprofile?.data?.client ? (
        <>
          {" "}
          <div className="flex flex-col items-center justify-center font-medium text-xl">
            <p>{t("Tell us a bit about your project")}</p>
          </div>
          <Steps current={currentStep}>
            {steps.map((step) => (
              <Step key={step.title} title={step.title} />
            ))}
          </Steps>
          <div style={{ marginTop: "20px" }}>{steps[currentStep]?.content}</div>
        </>
      ) : (
        <AsClientRegister />
      )}
    </div>
  );
};

export default PostProject;

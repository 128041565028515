// Import necessary components and icons
import { UserOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import { MessageList, Input, Avatar } from "react-chat-elements";
import "react-chat-elements/dist/main.css";
import { IoSendSharp } from "react-icons/io5";

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  useEffect(() => {
    setMessages([
      {
        position: "left",
        type: "text",
        text: "Welcome! How can I help you?",
        date: new Date(),
      },
    ]);
  }, []);

  const handleInputChange = (e) => {
    setInputMessage(e.target.value);
  };

  const handleSendMessage = () => {
    if (inputMessage.trim() !== "") {
      const sentMessage = {
        position: "right",
        type: "text",
        text: inputMessage,
        date: new Date(),
      };

      setMessages((prevMessages) => [...prevMessages, sentMessage]);
      setInputMessage("");

      setTimeout(() => {
        const receivedMessage = {
          position: "left",
          type: "text",
          text: "Hey!",
          date: new Date(),
        };

        setMessages((prevMessages) => [...prevMessages, receivedMessage]);
      }, 1000);
    }
  };

  const handleReply = (clickedMessage) => {
    setInputMessage(`Replying to: ${clickedMessage.text}`);
  };

  return (
    <div className="w-full h-full border border-gray-300 rounded overflow-hidden shadow">
      <div className="flex items-center gap-4">
      <Avatar size={64} icon={<UserOutlined />} />
        <h2 className="font-bold text-xl">Username</h2>
      </div>

      <div className="h-80 font-serif bg-gray-400 overflow-y-auto">
        <MessageList
          lockable={true}
          toBottomHeight={"100%"}
          dataSource={messages}
          onClickMessage={handleReply}
          replyButton={true}
        />
      </div>

      <Input
        className="border-4"
        placeholder="Type here..."
        multiline={false}
        onChange={handleInputChange}
        rightButtons={
          <button
            className="bg-blue-500 text-white p-2 rounded"
            onClick={handleSendMessage}
          >
            <IoSendSharp />
          </button>
        }
        value={inputMessage}
      />
    </div>
  );
};

export default Chat;

import { useTranslation } from "react-i18next";
import React from "react";

const Step3 = ({Image3}) => {
  const { t } = useTranslation();
  return (
    <div className="p-8 flex items-center justify-center">
      <div className="text-white flex flex-col items-center">
        <p className="font-semibold text-3xl">
          {t("Receive Bids and Choose a Freelancer")}
        </p>
        <div className="lg:flex">
          <img
            className="lg:w-2/6 md:w-3/6 w-5/6 mb-4 object-cover rounded"
            src={Image3}
            alt=""
          />
          <p className="grid items-center text-white text-lg text-center">
            {t(
              "Watch as freelancers submit their proposals for your project. You'll receive bids with quotes and estimated timelines. Review their profiles, ratings, and past work to make an informed decision."
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Step3;

import React from "react";
import EditProfile from "./Profile/EditProfile";
import { useTranslation } from "react-i18next";

const Settings = () => {
  const { t } = useTranslation();

  return (
    <div className="space-y-4">
      <div>
        <p className="font-medium text-lg">{t("Edit Profile")}</p>
      </div>
      <EditProfile />
    </div>
  );
};

export default Settings;

import {createContext, useContext, useEffect, useRef} from "react";
import {UserContext} from "./UserContext";

export const NotificationContext = createContext();


const NotificationsContext = ({children}) => {
    const {User} = useContext(UserContext);
    const audioRef = useRef(null)

    useEffect(() => {
        if (User) {
            setTimeout(() => {
                if (!isPermissionGranted()) {
                    if (shouldRequestPermission()) {
                        requestPermission()
                    }
                }
            }, 2000);
        }
    }, [User])

    function shouldRequestPermission(): boolean {
        const dateAskedNotificationPermission = localStorage.getItem('_dateAskedNotificationPermission')
        if (dateAskedNotificationPermission) {
            const currentDate = new Date();
            const askedDate = new Date(dateAskedNotificationPermission);

            const diffInTime = currentDate.getTime() - askedDate.getTime();
            const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24)); // Convert milliseconds to days

            return diffInDays > 1;
        } else {
            return true;
        }
    }

    function requestPermission() {
        if (!("Notification" in window)) {
            // console.log("Browser does not support desktop notification");
        } else {
            window.Notification.requestPermission().then(() => {
                localStorage.setItem('_dateAskedNotificationPermission', new Date().toISOString());
            })
        }
    }

    function isPermissionGranted() {
        return window.Notification.permission === 'granted'
    }

    function showNotification(notification) {
        if (!isPermissionGranted()) {
            if (shouldRequestPermission()) {
                requestPermission()
            }
            return
        }
        if (window.location.pathname !== '/app/professional/message') {
            new window.Notification(notification.title, {
                icon: `/images/logo.png`,
                body: notification.data.body || 'test...',
                tag: notification.data.tag || `${new Date().getMilliseconds().toString()}`
            })
            if (audioRef && audioRef.current) {
                audioRef.current.play();
            }
        }
    }

    return (
        <NotificationContext.Provider value={{fireNotification: showNotification}}>
            <audio ref={audioRef} src={'/notification/sound.mp3'} autoPlay={false}/>
            {children}
        </NotificationContext.Provider>
    );
};

export default NotificationsContext;

import React from "react";
import { Button, Col, Form, Input, Radio, Row, DatePicker, Select } from "antd";
import { useTranslation } from "react-i18next";

const { Option } = Select;
const EditProfile = () => {
  const { t } = useTranslation();

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        placeholder="Country Code"
        style={{
          width: 150,
        }}
      >
        <Option value="91">+91 India</Option>
        <Option value="1">+1 USA</Option>
      </Select>
    </Form.Item>
  );

  return (
    <div className=" flex flex-col items-center justify-center">
      <div className="bg-white p-8 rounded shadow-md md:w-[50%]">
        <Form layout="vertical" hideRequiredMark>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="firstname"
                label={t("First Name")}
                rules={[
                  {
                    required: true,
                    message: t("Please enter your First Name"),
                  },
                ]}
              >
                <Input placeholder={t("Please enter your First Name")} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="lastname"
                label={t("Last Name")}
                rules={[
                  {
                    required: true,
                    message: t("Please enter your Last Name"),
                  },
                ]}
              >
                <Input placeholder={t("Please enter your Last Name")} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="email"
                label={t("Email ID")}
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: t("Please enter your Email ID"),
                  },
                ]}
              >
                <Input placeholder={t("Please enter your Email ID")} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="phone"
                label={t("Phone Number")}
                rules={[
                  {
                    required: true,
                    message: t("Please input your phone number"),
                  },
                ]}
              >
                <Input
                  addonBefore={prefixSelector}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="current address"
                label={t("Current Address")}
                rules={[
                  {
                    required: true,
                    message: t("please enter your current address"),
                  },
                ]}
              >
                <Input.TextArea
                  rows={2}
                  placeholder={t("please enter your current address")}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="permanent address"
                label={t("Permanent Address")}
                rules={[
                  {
                    required: true,
                    message: t("please enter your permanent address"),
                  },
                ]}
              >
                <Input.TextArea
                  rows={2}
                  placeholder={t("please enter your permanent address")}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="gender"
                label={t("Gender")}
                rules={[
                  {
                    required: true,
                    message: t("Select Your Gender"),
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value="Male"> {t("Male")} </Radio>
                  <Radio value="Female"> {t("Female")} </Radio>
                  <Radio value="Other">{t("Other")} </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={t("Birth Date")}>
                <DatePicker />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="about me"
                label={t("About Me")}
                rules={[
                  {
                    required: true,
                    message: t("please tell us a little about yourself"),
                  },
                ]}
              >
                <Input.TextArea
                  rows={4}
                  placeholder={t("please tell us a little about yourself")}
                />
              </Form.Item>
              <Form.Item
                name="url"
                label={t("Project Url")}
                rules={[
                  {
                    required: true,
                    message: t("Please enter url"),
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  addonBefore="http://"
                  addonAfter=".com"
                  placeholder={t("Please enter your project url")}
                />
              </Form.Item>
            </Col>
          </Row>
          <div className="space-x-3">
            <Button style={{ backgroundColor: "Highlight" }} type="primary">
              {t("Cancel")}
            </Button>
            <Button style={{ backgroundColor: "Highlight" }} type="primary">
              {t("Submit")}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default EditProfile;

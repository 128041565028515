

import React, { useContext } from "react";
import { UserContext } from "../Context/UserContext";
import { Link } from "react-router-dom";
import { Spin } from "antd";

const ProtectedRoute = ({ Cmp, text }) => {
  const { User, loading } = useContext(UserContext);

  const loginRedirect = () => {
    sessionStorage.setItem("redirectUrl", window.location.pathname);
  };

  return (
    <div>
      {loading ? (
        <div className=" h-[50vh] flex justify-center items-center">
          <Spin size="large" />
        </div>
      ) : User ? (
        <Cmp />
      ) : (
        <Link
          to="/login"
          onClick={loginRedirect} 
          className="border-b-2 text-2xl border-[#1677ff] text-[#1677ff]"
        >
          Login to {text}
        </Link>
      )}
    </div>
  );
};

export default ProtectedRoute;

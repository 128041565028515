import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Input, Button, Checkbox } from "antd";

const ResetPassword = () => {
  const { t } = useTranslation();

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
  };

  return (
    <section className="bg-gray-900 min-h-screen flex items-center justify-center">
      <div className="w-full max-w-md bg-white  space-y-4 rounded-lg shadow-md p-6">
        <div className="flex items-center space-x-3">
          <img
            className="w-12 h-12 mr-2"
            src="../../../images/demologo.png"
            alt="logo"
          />
          <p className="text-3xl font-bold text-gray-900">Wory App</p>
        </div>
        <h2 className="mb-4 text-xl font-bold text-gray-900 ">
          {t("Change Password")}
        </h2>
        <Form
          name="resetPasswordForm"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          className="space-y-4"
        >
          <Form.Item
            name="email"
            label={t("Your email")}
            rules={[
              {
                type: "email",
                message: t("The input is not a valid email!"),
              },
              {
                required: true,
                message: t("Please input your email!"),
              },
            ]}
          >
            <Input placeholder="name@company.com" className="w-full" />
          </Form.Item>
          <Form.Item
            name="new-password"
            label={t("New Password")}
            rules={[
              {
                required: true,
                message: t("New Password"),
              },
            ]}
          >
            <Input placeholder="name@company.com" className="w-full" />
          </Form.Item>
          <Form.Item
            name="confirm-password"
            label={t("Confirm password")}
            rules={[
              {
                required: true,
                message: t("Confirm password"),
              },
            ]}
          >
            <Input placeholder="name@company.com" className="w-full" />
          </Form.Item>
          <Form.Item name="remember">
            <Checkbox>
              {t("I accept the")}{" "}
              <Link
                to="/terms"
                className="font-medium text-blue-600 hover:underline dark:text-blue-500"
              >
                {t("Terms and Conditions")}
              </Link>
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Link to="/login">
              <Button
                type="primary"
                style={{ backgroundColor: "#1677ff" }}
                htmlType="submit"
                className="w-full"
              >
                {t("Reset Password")}
              </Button>
            </Link>
          </Form.Item>
          <p className="text-sm font-light text-gray-500 dark:text-gray-400">
            {t("Already have an account.")}{" "}
            <Link
              to="/login"
              className="font-medium text-blue-600 hover:underline dark:text-blue-500"
            >
              {t("Login")}
            </Link>
          </p>
        </Form>
      </div>
    </section>
  );
};

export default ResetPassword;

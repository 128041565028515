import React, { useContext } from "react";
import { Button, Table, Tag } from "antd";
import { Link, useParams } from "react-router-dom";
import Order from "./Order";
import { SupportContext } from "../../Context/AdminContext";


const Orders = () => {
  const { id } = useParams();
  const {allJobsSupport } = useContext(SupportContext)
  
  const columns = [
    {
      title: "Order ID",
      dataIndex: "userId",
      key: "userId",
      render: (userId) => <span>#{userId}</span>,
    },
    // {
    //   title: "Client",
    //   dataIndex: "client",
    //   key: "serviceName",
    // },
    {
      title: "title",
      dataIndex: ["jobId","title"],
      key: "title",
    },
    // {
    //   title: "Area",
    //   dataIndex: "serviceName",
    //   key: "Area",
    // },
   { 
    title: "Status",
      dataIndex: "status",
      render: (text) => (
        <span className="flex items-center gap-2">
        {text === "IN_REVIEW" && <Tag className="bg-yellow-500 text-white font-bold">Under Review</Tag>}
        {text === "ACTIVE" && <Tag className="bg-blue-500 text-white font-bold">Active</Tag>}
        {text === "REJECTED" && <Tag className="bg-red-500 text-white font-bold">Rejected</Tag>}
        {text === "COMPLETED" && <Tag className="bg-green-500 text-white font-bold">Completed</Tag>}
      </span>
      ),
},
    {
      title: "action",
      dataIndex: "_id",
            render: (orderId) => (
        <Link to={`/support/orders/${orderId}`}>
        <Button>View Details</Button>
      </Link>    ),
    },
  ];

  if (id) {
    return <Order id={id} />;
  }

  

  return (
    <>
    
      
        <div
          className="lg:my-6 lg:mx-4 lg:p-6 my-5 mx-2 rounded-xl p-2 md:my-4 md:mx-2 md:p-4"
          style={{
            minHeight: "100vh",
            background: '',
          }}
        >
         <div>
      <Table dataSource={allJobsSupport} columns={columns} className="overflow-y-auto" />
    </div>
        </div>
      

    </>
  );
};

export default Orders;

import { createContext, useState } from "react";

export const JobContext = createContext();


const JobsContext = ({ children }) => {
    const [allJobs, setAllJobs] = useState([]);
    const [activeJobs, setActiveJobs] = useState([]);
    const [reviewJobs, setReviewJobs] = useState([]);
    const [completeJobs, setCompleteJobs] = useState([]);
    const [rejectedJobs, setRejectedJobs] = useState([]);
    const [activeKey, setActiveKey] = useState('1');
    const [reviewloader,setReviwLoader] = useState(false)

  return (
    <JobContext.Provider
    value={{
        allJobs, 
        setAllJobs,
        activeJobs,
        setActiveJobs,
        reviewJobs, setReviewJobs,
        completeJobs, setCompleteJobs,
        rejectedJobs, setRejectedJobs,
        activeKey, setActiveKey,
        reviewloader,setReviwLoader
    }}
    >
      {children}
    </JobContext.Provider>
  );
};

export default JobsContext;

import { useTranslation } from "react-i18next";
import React from "react";

const Section = () => {
  const { t } = useTranslation();
  return (
    <div className="mt-10 py-10">
      <div className="max-w-4xl lg:mx-auto p-6 mx-2 bg-white rounded-lg shadow-md">
        <h1 className="text-3xl font-semibold text-slate-700 mb-4">
          {t("Welcome to Wory App")}
        </h1>
        <p className="text-slate-600 mb-8">
          {t(
            "Your go-to platform for connecting freelancers with clients. Get the job done or find skilled professionals for your project needs."
          )}
        </p>

        <div className="lg:grid lg:grid-cols-2 space-y-2 gap-6 ">
          <div className="p-4 rounded-lg bg-blue-100">
            <h2 className="text-xl font-semibold text-slate-700 mb-2">
              {t("How It Works")}
            </h2>
            <ol className="list-decimal list-inside text-slate-700">
              <li>{t("Create an account")}</li>
              <li>{t("Post your project")}</li>
              <li>{t("Receive bids from talented freelancers")}</li>
              <li>{t("Select your freelancer and get started")}</li>
              <li>{t("Review and pay for completed work")}</li>
            </ol>
          </div>

          <div className="p-4 rounded-lg bg-green-100">
            <h2 className="text-xl font-semibold text-slate-700 mb-2">
              {t("Features & Functionalities")}
            </h2>
            <ul className="list-disc list-inside text-slate-700">
              <li>{t("Search and filter freelancers")}</li>
              <li>{t("Bid on projects that match your skills")}</li>
              <li>{t("Secure payments with escrow")}</li>
              <li>{t("Real-time messaging")}</li>
              <li>{t("Review and rating system")}</li>
              <li>{t("Project management tools")}</li>
            </ul>
          </div>
        </div>

        {/* <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
          Get Started
        </button> */}
      </div>
    </div>
  );
};

export default Section;

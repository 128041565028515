import React, { useContext, useEffect } from "react";
import { Menu, Dropdown, Avatar, Button, message } from "antd";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LangSwitch from "./LangSwitch";
import { UserContext } from "../Context/UserContext";
import axios from "axios";
import { ClientContext } from "../Context/ClientContext";
import { GoArrowSwitch } from "react-icons/go";
import { JobContext } from "../Context/JobContext";
import { SupportContext } from "../Context/AdminContext";

const UserDropdown = () => {
  const {
    User,
    setUser,
    setFullProfile,
    fullprofile,
    setLoading,
    setUserList,
    setSelectedUser,
  } = useContext(UserContext);
  const {
    loader,
    setClientUserList,
    setPostedJobs,
    setPendingJobs,
    setNotStartedJobs,
    setCompleteJobs,
  } = useContext(ClientContext);
  const { setActiveJobs, setReviewJobs, setRejectedJobs } =
    useContext(JobContext);
  const { setAlljobsSupport, setAllusers, setAllTags } =
    useContext(SupportContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isSupportPage = location.pathname.startsWith("/support");
  const handleLogout = () => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/users/logout`, {
        withCredentials: "true",
      })
      .then((res) => {
        message.success("Logout Successful");
        navigate('/login')
        setUser(null);
        setClientUserList([]);
        setUserList([]);
        setPostedJobs([]);
        setPendingJobs([]);
        setNotStartedJobs([]);
        setCompleteJobs([]);
        setActiveJobs([]);
        setReviewJobs([]);
        setRejectedJobs([]);
        setAlljobsSupport([]);
        setAllusers([]);
        setAllTags([]);
        navigate("/");
        console.log("res", res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/users/me`, {
        withCredentials: true,
      })
      .then((res) => {
        setLoading(false);
        setUser(res.data);
      })
      .catch((error) => {
        console.log("Error", error);
        setLoading(false);
      });
  }, []);

  const handleSwitchRole = () => {
    if (location.pathname.startsWith("/app/professional")) {
      setUserList([]);
      setSelectedUser(null);
      navigate("/app/client/postproject");
    } else if (location.pathname.startsWith("/app/client")) {
      setUserList([]);
      setSelectedUser(null);
      navigate("/app/professional/newDeals");
    } else if (location.pathname.startsWith("/support")) {
      setUserList([]);
      navigate("/app/professional/newDeals");
    }
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/users/me/full`, {
        withCredentials: true,
      })
      .then((res) => {
        setFullProfile(res.data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }, [loader]);

  const menu = (
    <>
      {User && (
        <>
          <Menu>
            <Menu.Item>
              <LangSwitch />
            </Menu.Item>
            <Menu.Item key="switchRole">
            {location.pathname.startsWith("/support/") &&  <div className="flex justify-center m-2">
                  {" "}
                  <Button
                    onClick={()=>navigate('/app/client/postproject')}
                    className="flex items-center"
                  >
                    <GoArrowSwitch />
                    Switch to Client
                  </Button>
                </div>}
              {location.pathname.startsWith("/app/professional") ? (
                <div className="flex justify-center">
                  {" "}
                  <Button
                    onClick={handleSwitchRole}
                    className="flex items-center"
                  >
                    <GoArrowSwitch />
                    Switch to Client
                  </Button>
                </div>
              ) : (
                <div>
                 
                   <div className="flex justify-center">
                  {" "}
                  <Button
                    onClick={handleSwitchRole}
                    className="flex items-center"
                  >
                    <GoArrowSwitch />
                    Switch to Professional
                  </Button>
                </div>
                </div>
               
              )}
            </Menu.Item>

            <Menu.Item>
              {fullprofile?.data.role === "ADMIN" && !isSupportPage && (
                <div className="flex justify-center">
                  <Button
                    onClick={() => navigate("/support/dashboard")}
                    className="flex items-center"
                  >
                    <GoArrowSwitch /> Switch to Support
                  </Button>
                </div>
              )}
            </Menu.Item>

            <Menu.Item key="logout">
              <div className="flex space-x-3">
                {User && (
                  <div onClick={handleLogout} className="flex">
                    <LogoutOutlined style={{ fontSize: 25 }} />
                    <p className="text-lg pl-2 font-semibold">{t("Logout")}</p>
                  </div>
                )}
              </div>
            </Menu.Item>
          </Menu>
        </>
      )}
    </>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <Button
        type="primary"
        size="middle"
        style={{ backgroundColor: "#1677ff", padding: 20 }}
        className="flex p-2 bg-gray-800 items-center cursor-pointer"
        onClick={(e) => e.preventDefault()}
      >
        {!User ? (
          <Link to="/login">
            <Avatar
              size="default"
              icon={<UserOutlined className="font-bold" />}
              style={{ marginRight: "14px" }}
            />
          </Link>
        ) : (
          <Avatar size="default" style={{ marginRight: "14px" }}>
            {User?.data.name
              ? User?.data?.name
                  ?.split(" ")
                  .map((x) => x.charAt(0).toUpperCase())
                  .join("")
              : ""}
          </Avatar>
        )}

        {User ? (
          <p className="font-bold"> {User.data.name}</p>
        ) : (
          <Link
            to="/login"
            onClick={() =>
              sessionStorage.setItem("redirectUrl", window.location.pathname)
            }
          >
            Login
          </Link>
        )}
      </Button>
    </Dropdown>
  );
};

export default UserDropdown;

import { useTranslation } from "react-i18next";
import React from "react";

const Step2 = ({Image2}) => {
  const { t } = useTranslation();
  return (
    <div className="p-8 flex items-center justify-center">
      <div className="text-white flex flex-col items-center">
        <p className="font-semibold text-3xl">{t("Post Your Project")}</p>
        <div className="lg:flex">
          <img
            className="lg:w-2/6 md:w-3/6 w-5/6 mb-4 object-cover rounded"
            src={Image2}
            alt=""
          />
          <p className="grid items-center text-lg text-center">
            {t(
              "If you're a client looking for help, post your project with all the details. Describe your requirements, budget, and deadline. Freelancers will review your project and send their proposals."
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Step2;

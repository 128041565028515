import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Input, Button, Checkbox, Spin, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import axios from "axios";
import { UserContext } from "../../Context/UserContext";

export default function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { signupData } = useContext(UserContext);
  const initialValues = {
    email: signupData?.email || "",
    password: "",
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const axiosConfig = {
        withCredentials: true,
      };

      const apiUrl = `${process.env.REACT_APP_API_URL}/users/login`;

      const response = await axios.post(apiUrl, values, axiosConfig);
      console.log(response);
      if (response.data.success) {
        message.success("Login Successful");
        const redirectUrl = sessionStorage.getItem("redirectUrl");
        if (redirectUrl) {
          navigate(redirectUrl);
        } else {
          navigate("/app/professional/newDeals");
        }
      } else {
        message.error(response.data.msg);
      }
    } catch (error) {
      console.log(error.response, "error");
      message.error(error.response.data.msg);
    } finally {
      setLoading(false);
    }
  };
  return (
    <section className="bg-gray-900 min-h-screen flex items-center justify-center">
      <div className="w-full max-w-md bg-white  space-y-4 rounded-lg shadow-md p-6">
        <div className="flex items-center space-x-3">
          <img
            className="w-12 h-12"
            src="../../../images/demologo.png"
            alt="logo"
          />
          <p className="text-3xl font-bold text-gray-900">Wory App</p>
        </div>
        <h1 className="text-xl font-bold text-gray-900">
          {t("Login to your account")}
        </h1>
        <Spin spinning={loading} delay={500} size="large">
          <Form
            name="login"
            style={{ color: "whitesmoke" }}
            className="login-form"
            onFinish={onFinish}
            initialValues={initialValues}
          >
            <Form.Item
              name="email"
              label={t("Your email *")}
              rules={[
                {
                  required: true,
                  message: t("Please input your Email!"),
                },
              ]}
              labelAlign="left"
              labelCol={{ span: 24 }}
              style={{ color: "black" }}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="name@company.com"
              />
            </Form.Item>
            <Form.Item
              name="password"
              label={t("Password *")}
              rules={[
                {
                  required: true,
                  message: t("Please input your Password!"),
                },
              ]}
              labelAlign="left"
              labelCol={{ span: 24 }}
              style={{ color: "black" }}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="••••••••"
              />
            </Form.Item>

            <Form.Item>
              <Link
                to="/reset-password"
                className="font-medium text-blue-600 hover:underline dark:text-blue-500"
              >
                {t("Forgot password?")}
              </Link>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                style={{ backgroundColor: "#1677ff" }}
                htmlType="submit"
                className="login-form-button"
              >
                {t("Log in")}
              </Button>
            </Form.Item>
          </Form>
        </Spin>

        <p className="text-sm font-light text-gray-500 dark:text-gray-400">
          {t("Don’t have an account yet?")}{" "}
          <Link
            to="/signup"
            className="font-medium text-blue-600 hover:underline dark:text-blue-500"
          >
            {t("Sign up")}
          </Link>
        </p>
        <p className="text-sm font-light text-gray-500 dark:text-gray-400">
          {t("Go Back to Home")}{" "}
          <Link
            to="/"
            className="font-medium text-blue-600 hover:underline dark:text-blue-500"
          >
            {t("Home")}
          </Link>
        </p>
      </div>
    </section>
  );
}

import { Spin, message } from "antd";
import axios from "axios";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ClientContext } from "../../Context/ClientContext";

const AsClientRegister = () => {
  const { setLoader, loader } = useContext(ClientContext);
  const navigate = useNavigate();
  const handleClick = () => {
    setLoader(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/client/register`,
        {},
        { withCredentials: true }
      )
      .then((res) => {
        setLoader(false);
        message.success(res.data.msg);
        navigate("/app/client/postproject");
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
        message.success("something wents wrong");
      });
  };
  return (
    <Spin spinning={loader} size="large">
      <div className=" min-h-[40vh] flex items-center align-middle flex-col justify-center">
        <div className="flex flex-col justify-center items-center bg-white shadow-lg p-4 h-[40vh] w-[50%]">
          <h2 className="text-xl mb-4">
            Your not authorize for Posting job Please Register As a client
          </h2>
          <button
            onClick={() => handleClick()}
            className="inline-flex text-white bg-blue-600 border-0 p-2 lg:px-16 focus:outline-none hover:bg-blue-400 rounded lg:text-lg"
          >
            Register as a client
          </button>
        </div>
      </div>
    </Spin>
  );
};

export default AsClientRegister;

import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "../../../Context/UserContext";
import axios from "axios";
import { Button, message } from "antd";
import { useNavigate } from "react-router-dom";

const useWompiCheckout = (handleFinish) => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.wompi.co/widget.js";
    document.body.appendChild(script);

    script.onload = () => {
      setIsScriptLoaded(true);
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const openCheckout = (config) => {
    if (isScriptLoaded && window.WidgetCheckout) {
      const checkout = new window.WidgetCheckout(config);

      checkout.open((result) => {
        const { transaction } = result;

        if (transaction.status === "APPROVED") {
          handleFinish();
        }
      });
    } else {
      console.error("Wompi script is not yet loaded.");
    }
  };

  return openCheckout;
};

const WompiTestTransaction = ({ orderDetailed, singleTag }) => {
  const { fullprofile } = useContext(UserContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleFinish = async () => {
    try {
      setLoading(true);
      const { area, ...orderDataWithoutArea } = orderDetailed;
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/jobs`,
        { ...orderDataWithoutArea, tagAssociated: singleTag[0]?._id },
        { withCredentials: true }
      );

      if (response.data.success) {
        navigate("/app/client/success");
        message.success("Order placed successfully!");
      } else {
        message.error("Failed to place the order. Please try again.");
      }
    } catch (error) {
      console.error("Error while sending order data:", error);
      message.error("Failed to place the order. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const openCheckout = useWompiCheckout(handleFinish);

  const handleOpenCheckout = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/wompi/checkout/create-transaction`,
        { withCredentials: true }
      );
      const referenceId = res.data.transactionId;
      console.log(fullprofile, "profile");
      openCheckout({
        language: "en",
        currency: "COP",
        amountInCents: 2490000,
        reference: referenceId,
        publicKey: "pub_test_6cdV8aGcKERfJcdlRIFzwpprSfmKWbFJ",
        redirectUrl: "https://transaction-redirect.wompi.co/check",
        customerData: {
          email: fullprofile?.data.email,
          fullName: fullprofile?.data.name,
          phoneNumber: "3040777777",
          phoneNumberPrefix: "+57",
          legalId: "123456789",
          legalIdType: "CC",
        },
      });
    } catch (error) {
      console.error("Error fetching reference ID:", error);
      message.error(
        "Failed to initiate the payment process. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      onClick={handleOpenCheckout}
      type="primary"
      style={{
        backgroundColor: "Highlight",
        margin: "0 8px",
      }}
      disabled={loading}
    >
      {loading ? "Processing..." : "Pay Now"}
    </Button>
  );
};

export default WompiTestTransaction;


import React, { useEffect } from "react";
import {  useNavigate } from "react-router-dom";

const PaymentSuccessPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      navigate("/app/client/orders");
    }, Math.floor(Math.random() * 3000) + 3000); 

    return () => clearTimeout(redirectTimeout);
  }, [navigate]);

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-3xl font-bold text-green-500 mb-4">Payment Done!</h1>
      <p className="text-lg text-gray-700">Redirecting to order page...</p>
    </div>
  );
};

export default PaymentSuccessPage;

import React, {  useState } from "react";
import { Button, Card, Modal } from "antd";
import ChatButton from "../Chat/ChatButton";

import { Content } from "antd/es/layout/layout";

const { Meta } = Card;
const sampleAppliedJobs = [
  {
    id: 1,
    title: 'React Developer',
    company: 'ABC Corp',
    appliedJobPrice: '$1000',
    dateApplied: '2023-01-15',
    timeApplied: '10:30 AM',
  },
  {
    id: 1,
    title: 'Nodejs Developer',
    company: ' Corp',
    dateApplied: '2023-01-15',
    timeApplied: '10:30 pm',
    appliedJobPrice: '$1000',
  }, {
    id: 1,
    title: 'React Developer',
    company: 'ABC Corp',
    dateApplied: '3',
    timeApplied: '10:30 pm',

    appliedJobPrice: '$1000',
  }, {
    id: 1,
    title: 'React Developer',
    company: 'ABC Corp',
    dateApplied: '1',
    timeApplied: '10:30 pm',
    appliedJobPrice: '$1000',
  },
];
const AppliedJobs = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);



  const showModal = (job) => {
    setSelectedJob(job);
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };


  return (
    <>
    
  
       
      <div>
      <Content
          className="lg:my-6 lg:mx-4 lg:p-6 my-5 mx-2 rounded-xl p-2 md:my-4 md:mx-2 md:p-4"
          style={{
            minHeight: "100vh",
            background: '',
          }}
        >
         <div className="flex justify-center flex-col items-center">
          {sampleAppliedJobs.map((job) => (
            <Card
            key={job.id}
            className="w-[50%]"
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '16px 0' }}
          >
            <div className="flex justify-between  ">
            <div>
              <Meta title={job.title} description={`Company: ${job.company}`} />
              <p>Applied Job Price: {job.appliedJobPrice}</p>
            </div>
            <div >
            <Button  onClick={() => showModal(job)}>
              Detail
            </Button>
            </div>
            </div>
          
          
          </Card>
          ))}
        </div>
    <ChatButton />
        </Content>
        <Modal
        title="Job Details"
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={[
          <Button key="close" onClick={handleModalCancel}>
            Close
          </Button>
        ]}
      >
        {selectedJob && (
          <>
            <p>User: Jhon die</p>
            <p>Date Applied: {selectedJob.dateApplied}</p>
            <p>Price: {selectedJob.timeApplied}</p>
            <p>Role: {selectedJob.title}</p>
            <p>Price: {selectedJob.appliedJobPrice}</p>
            <p>Company: {selectedJob.company}</p>
          </>
        )}
      </Modal>
      </div>
    

      

   
    </>
  );
};

export default AppliedJobs;;

import React from "react";
import { Table } from "antd";
import TruncatedColumn from "./Orders/TruncatedColumn";
import { IoArrowBack } from "react-icons/io5";
const columns = [
  {
    title: "Order Number",
    dataIndex: ["jobId", "jobId"],
    key: "jobId",
    width: "25%",
  },
  {
    title: "Name",
    dataIndex: ["jobPoster", "name"],
    key: "name",
    render: (text) => <p>{text}</p>,
  },
  {
    title: "Price",
    dataIndex: ["jobId", "minPrice"],
    key: "price",
    render: (text) => <p>${text}</p>,
  },
  {
    title: "Priority",
    dataIndex: ["jobId", "priority"],
    width: "15%",
  },
  {
    title: "Title",
    dataIndex: ["jobId", "title"],
    width: "15%",
  },
  {
    title: "Description",
    dataIndex: ["jobId", "description"],
    render: (text) => <TruncatedColumn text={text} />,
    width: "20%",
  },
];

const ChatDetails = ({ selectedUser, setSinglechat }) => {
  const dataSource = selectedUser ? [selectedUser] : [];
  console.log(selectedUser, "selectedUser single chat");
  return (
    <>
      <div className="">
        <IoArrowBack
          onClick={() => setSinglechat(false)}
          className="text-xl cursor-pointer"
        />
      </div>
      <Table
        columns={columns}
        dataSource={dataSource}
        className="w-[100%]"
        pagination={false}
      />
    </>
  );
};

export default ChatDetails;

import React, { useContext, useState } from "react";
import { Button, InputNumber, Modal, Spin, message, notification } from "antd";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../Context/UserContext";
import { useNavigate } from "react-router-dom";
import { JobContext } from "../../Context/JobContext";
import axios from "axios"
const AcceptButton = ({record}) => {
  console.log(record,"button record")
  const { t } = useTranslation();
  const navigate =useNavigate()
  const { setActiveKey,reviewloader,setReviwLoader } = useContext(JobContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { User } = useContext(UserContext);
  const [visible, setVisible] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const BaseUrl = process.env.REACT_APP_API_URL;

  const formatCreatedAt = (createdAt) => {
    const date = new Date(createdAt);
    return date.toLocaleString(); 
  };
  const MakeOffer =()=>{
          setReviwLoader(true)
       axios.post(`${BaseUrl}/jobs/status/request`,{
        jobId:record._id,
        price: record.price
      },{
        withCredentials:true
      }).then((res)=>{
        setReviwLoader(false)
        message.success("Applied for this order successfully");
        setVisible(false);
        setIsModalOpen(false);
        setActiveKey('2'); 
        navigate("/app/professional/orders");
      }).catch((err)=>{
        setReviwLoader(false);
        setVisible(false);
        setIsModalOpen(false);
        message.error(err.response.data.msg )
      })
  }
  const handleOk = () => {
    setVisible(true);
    //   if (!User) {
    //   message.error("Please login to your account first");
    // } else {
    //   setReviwLoader(true)
    //   axios.post(`${BaseUrl}/jobs/status/request`,{
    //     jobId:record._id,
    //     price: record.price
    //   },{
    //     withCredentials:true
    //   }).then((res)=>{
    //     setReviwLoader(false)
    //     message.success("Applied for this order successfully")
    //     setActiveKey('2'); 
    //     navigate("/app/professional/orders");
    //   }).catch((err)=>{
    //     setReviwLoader(false)
    //     message.error(err.response.data.msg )
    //   })
    
    // }
  };
  
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCancelModel =()=>{
    setVisible(false);

  }
  const handleShow =()=>{
    MakeOffer();
  }
  return (
    <> 
      <Button
        type="primary"
        className="text-white bg-blue-500"
        onClick={showModal}
      >
        {t("Make an offer")}
      </Button>
      <Modal
        title=""
        visible={visible}
        onOk={handleShow}
        onCancel={handleCancelModel}
        okButtonProps={{ className:"text-white bg-blue-500" }}
      >
         <Spin spinning={reviewloader}>        <p className="text-[16px]">Are you sure you want to make an offer?</p>
         </Spin>
       
      </Modal>
      <Modal
        centered
        open={isModalOpen}
        onOk={handleOk}
        okType="default"
        onCancel={handleCancel}
      >
        <div className="flex space-y-6 flex-col ">
          <div>
            <p className="font-bold text-xl text-[#001529]">
              {t("Order Info")}
            </p>
          </div>
          <div className="flex flex-col justify-center text-[#001529] text-lg ">
            <p>{t("Order Number")} : {record?.jobId}</p>
            {/* <p>{t("Client Local Time")} : 12:09 PM (GMT-5)</p> */}
            <p>{t("Priority")} : {record?.priority}</p>
            {/* <p>{t("Client Name")} : {selectedOrder?.client}</p> */}
            <p>{t("Title")} : {record?.title}</p>
            <p>{t("Client Name")} : {record?.jobPoster[0]?.name}</p>
            <p>{t("Price")} : {record?.price}$</p>      
            <p>{t("Job Posted At")} : {formatCreatedAt(record?.createdAt)}</p>  
            <div className="flex">
            {/* <p>Enter Your Offer Price:</p>
            <div>
            <InputNumber
                placeholder="Enter your offer price"
                value={offerPrice}
                onChange={HandlechangePrice}
                type="number"
                className="w-full"
              /> 
 <p className="text-red-700 text-md">{errortext ? `Price must be in range $${record.minPrice} to $${record.maxPrice}` : ""}</p>            </div>
             */}
            </div>
         
                </div>
        </div>
      </Modal>
    </>
  );
};
export default AcceptButton;

import React from "react";
import { useTranslation } from "react-i18next";

const Payout = () => {
  const { t } = useTranslation();

  return (
    <div className="space-y-4">
      <div className="bg-slate-700 p-4 flex space-x-3  rounded-md">
        <p className="font-bold text-xl text-white ">{t("Greetings")}</p>
        <div className=" text-white animate-pulse">
          <button className="rounded-md p-2 bg-yellow-300">
            Coming Soon ...
          </button>
        </div>
      </div>
      <div>
        <p>
          Professionals can sell too. If this happens, they can get 50% of the
          order
        </p>
      </div>
    </div>
  );
};

export default Payout;

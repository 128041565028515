import { Button, Form, Modal, Spin } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { useRef, useContext } from 'react'
import Swal from 'sweetalert2'
import axios from "axios"
import  { SupportContext } from '../Context/AdminContext'
import { useLocation, useNavigate } from 'react-router-dom'

const ComplaintsAdminModal = ({ modalVisible, handleModalCancel, setModalVisible, complaintType, record }) => {
  const {  complaintloading, setComplaintLoading
  } = useContext(SupportContext);
  const navigate = useNavigate();
  const location = useLocation();
  const formRef = useRef();

  const handleSubmit = (values) => {
    setComplaintLoading(true);
    axios.post(`${process.env.REACT_APP_API_URL}/complaint/create`, {
      ...values,
      complaintType: complaintType,
      complaint:values.complaint,
      // freelancerId: record.userId._id,
      // clientId: record.jobPoster._id,
      jobId: record._id
    }, { withCredentials: true })
      .then((res) => {
        setComplaintLoading(false);
        setModalVisible(false);
        Swal.fire({
          text: 'Complaint successfully submitted to the admin',
          icon: 'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK',
        }).then(()=>{
          const { pathname } = location;
          const isProfessionalPath = pathname.includes('/professional');
          const isClientPath = pathname.includes('/client');

          if(isProfessionalPath){
            navigate('/app/professional/complaints')
          } 
          
          if(isClientPath){
            navigate('/app/client/complaints')
          } 
         
        })
        formRef.current.resetFields();
      })
      .catch(err => {
        setComplaintLoading(false);
        console.log(err);
      });
  };

  return (
    <Modal
      title="Complaint to admin"
      visible={modalVisible}
      onCancel={handleModalCancel}
      footer={null}
    >
      <Spin spinning={complaintloading}>
        <Form ref={formRef} onFinish={handleSubmit}>

          <Form.Item
            label="Complaint"
            name="complaint"
            rules={[{ required: true, message: 'Please enter a complaint' }]}
          >
            <TextArea rows={4} placeholder='enter your complaint'/>
          </Form.Item>

          <Form.Item>
            <div className="flex justify-center">
              <Button htmlType="submit">
                Submit
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}

export default ComplaintsAdminModal;

import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  MoneyCollectOutlined,
  OrderedListOutlined,
  ShoppingOutlined,
  TagsOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Layout, Menu, theme } from "antd";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { TiMessageTyping } from "react-icons/ti";
import { Footer } from "antd/es/layout/layout";
import Footer1 from "../Landing/Footer1";
import UserDropdown from "../UserDropdown";
import { MdOutlineLibraryBooks, MdOutlineWorkOutline } from "react-icons/md";
import ChatButton from "../Chat/ChatButton";
import { UserContext } from "../../Context/UserContext";
import Complaints from "../Professional/Complaints";

const { Header, Sider, Content } = Layout;

const SidebarPro = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [openKeys, setOpenKeys] = useState([]);
  const { User } = useContext(UserContext);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const menuItems = [
    {
      key: "newDeals",
      icon: <OrderedListOutlined />,
      label: t("New Deals"),
      path: "/app/professional/newDeals",
    },
    {
      key: "recommended orders",
      icon: <MdOutlineWorkOutline />,
      label: t("Recommended Orders"),
      path: "/app/professional/recommended-orders",
    },
    {
      key: "orders",
      icon: <ShoppingOutlined />,
      label: t("Orders"),
      path: "/app/professional/orders",
    },
    {
      key: "userProfile",
      icon: <UserOutlined />,
      label: t("User Profile"),
      path: "/app/professional/profile",
    },
    {
      key: "Tags",
      icon: <TagsOutlined />,
      label: t("My Tags"),
      path: "/app/professional/my-tags",
    },
    {
      key: "balance",
      icon: <MoneyCollectOutlined />,
      label: t("Balance"),
      path: "/app/professional/balance",
    },
    {
      key: "message",
      icon: <TiMessageTyping />,
      label: t("Message"),
      path: "/app/professional/message",
    },
    {
      key: "complaints",
      icon: <MdOutlineLibraryBooks />,
      label: t("Complaints"),
      content: <Complaints />,
      path: "/app/professional/complaints",
    },
  ];
  const memoizedMenuItems = useMemo(() => {
    return menuItems;
  }, []);
  const filteredMenuItems = User
    ? menuItems
    : menuItems.filter((item) => item.key === "newDeals");

  useEffect(() => {
    const currentOpenKeys = memoizedMenuItems
      .filter((item) => location.pathname.startsWith(item.path))
      .map((item) => item.key);
    setOpenKeys(currentOpenKeys);
  }, [location.pathname, memoizedMenuItems]);

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        breakpoint="xxl"
        collapsedWidth="0"
        collapsible
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <div className="demo-logo-vertical" />

        <div className="demo-logo-vertical text-white flex flex-col justify-center space-y-3 items-center my-3">
          <Link to="/">
            <img className="h-12" src="../../images/logo.png" alt="" />
            <p className="font-extrabold text-xl text-white">WORY</p>
          </Link>
        </div>

        <Menu
          theme="dark"
          mode="inline"
          style={{ height: "100%", borderRight: 0 }}
          selectedKeys={[location.pathname]}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
        >
          {filteredMenuItems.map((item) => {
            if (
              item.path === "/app/professional/message/pitter" &&
              location.pathname !== item.path
            ) {
              return null;
            }
            return (
              <Menu.Item
                key={item.path}
                icon={item.icon}
                onClick={() => navigate(item.path)}
              >
                <Link to={item.path}>{item.label} </Link>
              </Menu.Item>
            );
          })}
        </Menu>
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <div className=" bg-[#002140] p-4 flex place-content-between">
            <div className="flex space-x-5">
              <p className="font-bold text-xl pt-1 text-white ">
                {location.pathname.endsWith("/userProfile")
                  ? "Profile"
                  : location.pathname.endsWith("/recommended-orders")
                  ? "Recommended Orders"
                  : location.pathname.endsWith("/newDeals")
                  ? "New Deals"
                  : location.pathname.endsWith("/my-tags")
                  ? "My Tags"
                  : t(location.pathname.split("/").pop())
                      .charAt(0)
                      .toUpperCase() +
                    t(location.pathname.split("/").pop()).slice(1)}
              </p>
            </div>
            <div className="flex space-x-5">
              <UserDropdown />
            </div>
          </div>
        </Header>
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          {" "}
          <Outlet />
          <ChatButton />
        </Content>
        <Footer style={{ textAlign: "center" }}>
          <Footer1 />
        </Footer>
      </Layout>
    </Layout>
  );
};

export default SidebarPro;

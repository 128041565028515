import { useTranslation } from "react-i18next";
import React from "react";

const Step4 = ({Image4}) => {
  const { t } = useTranslation();
  return (
    <div className="p-8 flex items-center justify-center">
      <div className="text-white flex flex-col items-center">
        <p className="font-semibold text-3xl">
          {t("Collaborate and Complete the Project")}
        </p>
        <div className="lg:flex">
          <img
            className="lg:w-2/6 md:w-3/6 w-5/6 mb-4 object-cover rounded"
            src={Image4}
            alt=""
          />
          <p className="grid items-center text-white text-lg text-center">
            {t(
              "Once you've selected a freelancer, collaborate through our messaging system. Monitor project progress, provide feedback, and ensure everything is on track. When the project is complete, release payment and leave a review."
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Step4;

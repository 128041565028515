import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LangSwitch from "../LangSwitch";

const Header = () => {
  const { t } = useTranslation();
  return (
    <>
      <header className="text-gray-600 body-font">
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
          <div className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
            <Link href="/" className="block">
              <img className="h-12" src="../../images/logo.png" alt="" />
            </Link>
            <span className="ml-3 font-bold text-white pt-2 text-2xl">
              Wory App
            </span>
          </div>
          <nav className="md:ml-auto ml-3 flex flex-wrap items-center text-base justify-center">
            <div className="text-white strict">
              <LangSwitch />
            </div>
          </nav>
          <div className="inline-block space-x-2 mt-5 md:mt-0 md:ml-3 lg:flex">
            <button>
              <Link
                to="/signup"
                className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                {t("Sign up")}
              </Link>
            </button>
            <button>
              <Link
                to="/login"
                className="w-full text-blue-600 bg-white hover:text-white hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-white dark:hover:bg-blue-700 dark:focus:ring-blue-800 border border-blue-600"
              >
                {t("Log in")}
              </Link>
            </button>
          </div>
        </div>
      </header>
    </>
  );
};
export default Header;

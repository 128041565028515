import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  OrderedListOutlined,
  ShoppingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Layout, Menu, theme } from "antd";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { TiMessageTyping } from "react-icons/ti";
import { Footer } from "antd/es/layout/layout";
import Footer1 from "../Landing/Footer1";
import UserDropdown from "../UserDropdown";
import ChatButton from "../Chat/ChatButton";
import { UserContext } from "../../Context/UserContext";
import PostProject from "./PostProject/PostProject";
import Orders from "./Orders/Orders";
import UserProfile from "../UserProfile";
import { MdOutlineLibraryBooks } from "react-icons/md";
import ComplaintsClient from "./Complaints";

const { Header, Sider, Content } = Layout;

const Client = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [openKeys, setOpenKeys] = useState([]);
  const { User,fullprofile } = useContext(UserContext);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const menuItems = [
    {
      key: "postProject",
      icon: <OrderedListOutlined />,
      label: t("Post Project"),
      content: <PostProject />,
      path: "/app/client/postproject",
    },
    {
      key: "orders",
      icon: <ShoppingOutlined />,
      label: t("Orders"),
      content: <Orders />,
      path: "/app/client/orders",
    },
    {
      key: "userProfile",
      icon: <UserOutlined />,
      label: t("User Profile"),
      content: <UserProfile />,
      path: "/app/client/profile",
    },
    {
      key: "message",
      icon: <TiMessageTyping />,
      label: t("Message"),
      path: "/app/client/message",
    },
    {
      key: "complaints",
      icon: <MdOutlineLibraryBooks />,
      label: t("Complaints"),
      content: <ComplaintsClient />,
      path: "/app/client/complaints",
    },

  ];

  const memoizedMenuItems = useMemo(() => {
    return menuItems;
  }, []);

  if (!fullprofile?.data?.client || (fullprofile?.data && !fullprofile?.data?.client)) {
    const ordersIndex = menuItems.findIndex(item => item.key === "orders");
    if (ordersIndex !== -1) {
      menuItems.splice(ordersIndex, 1);
    }
  }
  if (!fullprofile?.data?.client || (fullprofile?.data && !fullprofile?.data?.client)) {
    const ordersIndex = menuItems.findIndex(item => item.key === "message");
    if (ordersIndex !== -1) {
      menuItems.splice(ordersIndex, 1);
    }
  }
  const filteredMenuItems = User ? menuItems : menuItems.filter(item => item.key === "postProject");
 
  useEffect(() => {
    const currentOpenKeys = memoizedMenuItems
      .filter((item) => location.pathname.startsWith(item.path))
      .map((item) => item.key);
    setOpenKeys(currentOpenKeys);
  }, [location.pathname, memoizedMenuItems]);

 

  useEffect(() => {
    const currentOpenKeys = filteredMenuItems
      .filter((item) => location.pathname.startsWith(item.path))
      .map((item) => item.key);
    setOpenKeys(currentOpenKeys);
  }, [location.pathname]);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        breakpoint="xxl"
        collapsedWidth="0"
        collapsible
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <div className="demo-logo-vertical" />
        <div className="demo-logo-vertical text-white flex flex-col justify-center space-y-3 items-center my-3">
          <img className="h-12" src="../../images/logo.png" alt="" />
          <p className="font-extrabold text-xl text-white">WORY</p>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          style={{ height: "100%", borderRight: 0 }}
          selectedKeys={[location.pathname]}
          openKeys={openKeys}
          onOpenChange={setOpenKeys}
        >
          {filteredMenuItems.map((item) => (
            <Menu.Item
              key={item.path}
              icon={item.icon}
              onClick={() => navigate(item.path)}
            >
              <Link to={item.path}>{item.label}</Link>
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <div className=" bg-[#002140] p-4 flex place-content-between">
            <div className="flex space-x-5">
              <p className="font-bold text-xl pt-1 text-white ">
                {location.pathname.endsWith("/userProfile")
                  ? "Profile"
                  : location.pathname.endsWith("/postproject")
                  ? "Post Project"
                  : t(location.pathname.split("/").pop())
                      .charAt(0)
                      .toUpperCase() +
                    t(location.pathname.split("/").pop()).slice(1)}
              </p>
            </div>
            <div className="flex space-x-5">
              <UserDropdown />
            </div>
          </div>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
            overflow: "auto",
          }}
        >
          <Outlet />
          <ChatButton />
        </Content>
        <Footer style={{ textAlign: "center" }}>
          <Footer1 />
        </Footer>
      </Layout>
    </Layout>
  );
};

export default Client;

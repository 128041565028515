import { Spin, Tooltip, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { UserContext } from "../../Context/UserContext";
import CreatableSelect from "react-select/creatable";
import { FaArrowUp } from "react-icons/fa";

const JobTags = () => {
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const { userTag, setUserTag } = useContext(UserContext);
  const [hoveredTagId, setHoveredTagId] = useState(null);
  const handleSelectChange = (selectedOptions) => {
    setSelectedTags(selectedOptions);
  };
  console.log(selectedTags, "selected tags");

  useEffect(() => {
    const userTags = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/tags`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      const data = await response.json();
      if (data.success) {
        setUsersData(data.tags);
      } else {
        console.log("Code is wrong");
      }
    };
    userTags();
  }, []);
  const handleSend = async () => {
    if (selectedTags[0]?.__isNew__) {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/tags/by-name`,
          { name: selectedTags[0].value },
          { withCredentials: true }
        )
        .then((res) => {
          setLoading(false);
          setSelectedTags([]);
          message.success("Tag posted");
        })
        .catch((error) => {
          console.error("Error while posting tag:", error);
          setLoading(false);
          setSelectedTags([]);
          message.error("Failed to post tag");
        });
    } else {
      const tagIds = selectedTags.map((tag) => tag.value);
      setLoading(true);
      try {
        console.log("Sending request to /api/tags");
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/tags`,
          {
            tags: tagIds,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );

        const data = response.data;

        if (data.success) {
          if (selectedTags.length === 0) {
            setLoading(false);
            message.error("Please select tag first");
          } else {
            setSelectedTags([]);
            message.success("Tags added successfully");
            setLoading(false);
          }
        } else {
          message.error(data.message || "Failed to add tags");
        }
      } catch (error) {
        setLoading(false);
        console.error("Error adding tags:", error);
        message.error(error.response.data.msg);
      }
    }
  };

  // const handleSend = async () => {
  //   if (selectedTags[0]?.__isNew__) {
  //     setLoading(true);
  //     axios
  //       .post(
  //         `${process.env.REACT_APP_API_URL}/tags/by-name`,
  //         { name: selectedTags[0].value },
  //         { withCredentials: true }
  //       )
  //       .then((res) => {
  //         setLoading(false);
  //         message.success("Tag posted");
  //         setSelectedTags([]);
  //       })
  //       .catch((error) => {
  //         console.error("Error while posting tag:", error);
  //         setLoading(false);
  //         setSelectedTags([]);
  //       });
  //   } else {
  //     const tagIds = selectedTags.map((tag) => tag.value);
  //     setLoading(true);
  //     try {
  //       console.log("Sending request to /api/tags");
  //       const response = await axios.post(
  //         `${process.env.REACT_APP_API_URL}/tags`,
  //         {
  //           tags: tagIds,
  //         },
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           withCredentials: true,
  //         }
  //       );

  //       const data = response.data;

  //       if (data.success) {
  //         if (selectedTags.length === 0) {
  //           setLoading(false);
  //           message.error("Please select tag first");
  //         } else {
  //           setSelectedTags([]);
  //           message.success("Tags added successfully");
  //           setLoading(false);
  //         }
  //       } else {
  //         message.error(data.message || "Failed to add tags");
  //       }
  //     } catch (error) {
  //       setLoading(false);
  //       console.error("Error adding tags:", error);
  //       message.error(error.response.data.msg);
  //     }
  //   }
  // };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/tags/my`, {
        withCredentials: true,
      })
      .then((res) => {
        setUserTag(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [loading]);
  // Inside handleDelete function
  const handleDelete = (id) => {
    setLoading(true);

    axios
      .delete(`${process.env.REACT_APP_API_URL}/tags/${id}`)
      .then((res) => {
        message.success("Tag deleted successfully");
        setLoading(false);

        // Remove the deleted tag from the userTag state
        setUserTag((prevUserTag) => ({
          ...prevUserTag,
          tags: prevUserTag.tags.filter((tag) => tag._id !== id),
        }));
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error deleting tag:", error);
        message.error("Failed to delete tag");
      });
  };

  // const handleDelete = (id) => {
  //   setLoading(true);

  //   axios
  //     .delete(`${process.env.REACT_APP_API_URL}/tags/${id}`)
  //     .then((res) => {
  //       message.success("Tag deleted successfully");
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       console.error("Error deleting tag:", error);
  //       message.error("Failed to delete tag");
  //     });
  // };

  return (
    <>
      <Spin spinning={loading} size="large">
        <div className="p-4 flex gap-5 justify-center items-center">
          <div className="md:w-2/3">
            <CreatableSelect
              value={selectedTags}
              onChange={handleSelectChange}
              options={usersData
                ?.filter((tag) => {
                  return !userTag.tags?.some(
                    (userTag) => userTag._id === tag._id
                  );
                })
                .map((user) => ({
                  value: user?._id,
                  label: user?.name,
                }))}
              placeholder="Select or type to add tags"
              isMulti
              className="w-full _placeholder:text-red-800"
              isClearable
              formatCreateLabel={(inputValue) => ` ${inputValue}`}
            />
          </div>
          <div>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
              onClick={handleSend}
            >
              Add Tag
            </button>
          </div>
        </div>
        <div>
          <div className="flex justify-center flex-wrap">
            <div className="min-h-[35vh] lg:w-[60%] w-[80%] rounded-md m-5 p-8">
              <div className="flex justify-center flex-wrap">
                {userTag?.tags?.length === 0 ? (
                  <div className="w-full">
                    <div className="h-[40vh] backdrop-filter bg-white shadow-xl backdrop-blur-lg flex items-center justify-center rounded-lg">
                      <h2 className="text-2xl text-black font-semibold">
                        <span className="flex justify-center items-center">
                          Add Your First Tag Using Dropdown{" "}
                          <FaArrowUp className="ms-2" />
                        </span>
                      </h2>
                    </div>
                  </div>
                ) : (
                  Array.isArray(userTag.tags) &&
                  userTag?.tags.map((tag) => (
                    <Tooltip title="delete" key={tag._id}>
                      <div
                        onMouseEnter={() => setHoveredTagId(tag._id)}
                        onMouseLeave={() => setHoveredTagId(null)}
                        onClick={() => handleDelete(tag._id)}
                        key={tag._id}
                        className="p-2 text-lg m-4 shadow-md hover:shadow-xl hover:line-through rounded-full cursor-pointer flex border-2"
                      >
                        <span>{tag.name}</span>
                      </div>
                    </Tooltip>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
        ;
      </Spin>
    </>
  );
};

export default JobTags;

import { useTranslation } from "react-i18next";
import React from "react";
import { Link } from "react-router-dom";

const Hero = () => {
  const { t } = useTranslation();
  return (
    <div>
      <section className="text-gray-600 body-font">
        <div className="container mx-auto flex px-5 py-6 items-center flex-col ">
          <img
            className="lg:w-1/6 md:w-3/6 w-5/6 mb-4 object-cover object-center rounded"
            alt="hero"
            src="./images/2.png"
          />
          <div className="text-center space-y-5 lg:w-2/3 w-full">
            <h6 className="font-black text-5xl text-gray-200">Wory</h6>
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">
              {t(
                "It’s not a “Freelancer platform”. We don’t offer jobs for hours"
              )}
            </h1>
            <p className="mb-8 text-lg leading-relaxed">
              {t(
                "We are a platform for professional nano-jobs. Jobs that can be done in hours and doesn’t demand follow-ups or classic structur for teamworks."
              )}
            </p>
            <div className="flex  space-x-5 justify-center">
              <div>
                <Link to="/app/client/postproject">
                  <button className="inline-flex text-white bg-blue-600 border-0 p-2 lg:px-16 focus:outline-none hover:bg-blue-400 rounded lg:text-lg">
                    {t("Post a Job")}
                  </button>
                </Link>
                <p className="text-xl text-white mt-2">{t("*for clients")}</p>
              </div>

              <div>
                <Link to="/app/professional/newDeals">
                  <button className="inline-flex text-white bg-blue-600 border-0 p-2 lg:px-4 focus:outline-none hover:bg-blue-400 rounded lg:text-lg">
                    {t("Earn FreeLancing Money")}
                  </button>
                </Link>
                <p className="text-xl text-white mt-2">{t("*for professionals")}</p>

              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hero;

import { Tag } from "antd";
import { FaUsers } from "react-icons/fa";
import { IoBagOutline } from "react-icons/io5";
import React, { useContext } from "react";
import MonthlyUsers from "./MonthlyUser";
import PieChart from "./PieChart";
import { Link } from "react-router-dom";
import {
  OrderedListOutlined,
  ShoppingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Allusers from "./Alluser";
import Orders from "./Orders";
import { CiShoppingTag } from "react-icons/ci";
import Tags from "./Tags";
import { useTranslation } from "react-i18next";
import { SupportContext } from "../../Context/AdminContext";
import { MdOutlineLibraryBooks } from "react-icons/md";

const Dashboard = () => {
  const { t } = useTranslation();
  const { allUsers, allJobsSupport } = useContext(SupportContext);

  const menuItems = [
    {
      key: "dashboard",
      icon: <OrderedListOutlined />,
      label: t("Dashboard"),
      content: <Dashboard />,
      path: "/dashboard",
    },
    {
      key: "allusers",
      icon: <UserOutlined />,
      label: t("All Users"),
      content: <Allusers />,
      path: "/dashboard/allusers",
    },
    {
      key: "orders",
      icon: <ShoppingOutlined />,
      label: t("Orders"),
      content: <Orders />,
      path: "/dashboard/orders",
    },
    {
      key: "tags",
      icon: <CiShoppingTag />,
      label: t("Tags"),
      content: <Tags />,
      path: "/dashboard/tags",
    },
  ];

  return (
    <div>
      <div className=" flex gap-5  justify-evenly flex-wrap ">
        <div className="flex items-center justify-between border p-4 rounded-md shadow-md  h-[20vh] cursor-pointer">
          <div className="flex items-center ">
            <FaUsers className="text-4xl mr-2" />
          </div>
          <div className="p-4">
            <Link to="/support/allusers">
              <span className="text-2xl font-bold">Total Users</span>
              <br />
              <span className="text-2xl font-bold">
                <Tag color="blue">{allUsers?.length}</Tag>
              </span>
            </Link>
          </div>
        </div>

        <div className="flex items-center justify-between border p-4 rounded-md shadow-md  h-[20vh] cursor-pointer">
          <div className="flex items-center">
            <IoBagOutline className="text-4xl mr-2" />
          </div>
          <div className="p-4">
            <Link to="/support/orders">
              <span className="text-2xl font-bold">Total Orders</span>
              <br />
              <span className="text-2xl font-bold">
                <Tag color="blue">{allJobsSupport?.length}</Tag>
              </span>
            </Link>
          </div>
        </div>

        <div className="flex items-center justify-between border p-4 rounded-md shadow-md  h-[20vh]">
          <div className="flex items-center">
            <IoBagOutline className="text-4xl mr-2" />
          </div>
          <div className="p-4">
            <Link to="/support/applied-order">
              <span className="text-2xl font-bold">Applied Orders</span>
              <br />
              <span className="text-2xl font-bold">
                <Tag color="blue">20</Tag>
              </span>
            </Link>
          </div>
        </div>
        <div className="flex items-center justify-between border p-4 rounded-md shadow-md  h-[20vh]">
          <div className="flex items-center">
            <MdOutlineLibraryBooks className="text-4xl mr-2" />
          </div>
          <div className="p-4">
            <Link to="/support/complaints">
              <span className="text-2xl font-bold">Complaints</span>
              <br />
              <span className="text-2xl font-bold">
                <Tag color="blue">2</Tag>
              </span>
            </Link>
          </div>
        </div>
      </div>
      <MonthlyUsers />

      <div className="flex gap-0  flex-wrap ">
        <div className="flex-1 w-[55%]">
          <PieChart />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

import React, { useContext, useState } from "react";
import { Button, Modal, Spin, message } from "antd";
import UserInfo from "./UserInfo";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { ClientContext } from "../../Context/ClientContext";
const AcceptButton = ({ record }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { loadingjobs, setLoadingJobs, deletjobloader, setDeleteJobLoader } =
    useContext(ClientContext);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setLoadingJobs(true);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/client/approve`,
        {
          _id: record._id,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        message.success("Order Accepted");
        setIsModalOpen(false);
        setLoadingJobs(false);
      })
      .catch((err) => {
        message.error("Something went wrong");
        setIsModalOpen(false);
        setLoadingJobs(false);
      });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const HandledeleteJob = (id) => {
    setDeleteJobLoader(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/client/reject`,
        {
          _id: id,
        },
        { withCredentials: true }
      )
      .then((res) => {
        setDeleteJobLoader(false);
        message.success("Order Deleted Successfully");
        setIsModalOpen(false);
      })
      .catch((err) => {
        setDeleteJobLoader(false);
        message.error("Something wents wrong");
        setIsModalOpen(false);
      });
  };
  const formatCreatedAt = (createdAt) => {
    const date = new Date(createdAt);
    return date.toLocaleString();
  };
  return (
    <>
      <Button
        type="primary"
        className="text-white bg-blue-500"
        onClick={showModal}
      >
        {t("Detail")}
      </Button>
      <Modal
        centered
        open={isModalOpen}
        onOk={handleOk}
        okType="default"
        okText="Accept"
        cancelText="Delete"
        cancelButtonProps={{
          className: "bg-red-500 text-white hover:text-white hidden",
        }}
        onCancel={handleCancel}
      >
        <Spin spinning={loadingjobs}>
          <Spin spinning={deletjobloader}>
            <div className="flex space-y-6 flex-col ">
              <div>
                <p className="font-bold text-xl text-[#001529]">
                  {t("Order Info for Client")}
                </p>
              </div>
              <div className="flex flex-col justify-center text-[#001529] text-lg ">
                <p>
                  {t("Order Number")} : {record?.jobId.jobId}
                </p>
                {/* <p>{t("Client Local Time")} : 12:09 PM (GMT-5)</p> */}
                <p>
                  {t("Priority")} : {record?.jobId.priority}
                </p>
                {/* <p>{t("Client Name")} : {selectedOrder?.client}</p> */}
                <p>
                  {t("Title")} : {record?.jobId.title}
                </p>
                <p>
                  {t("Client Name")} : {record?.jobPoster?.name}
                </p>
                <p>
                  {t("Price")} : ${record?.jobId?.price}
                </p>
                <p>
                  {t("User Name")} : {record?.userId?.name}
                </p>
                {/* <p>
                  {t("Freelancer Price")} : ${record?.price}{" "}
                </p> */}
                <p>
                  {t("Job Posted At")} : {formatCreatedAt(record?.createdAt)}
                </p>
                <div className="relative top-[30px]">
                  <UserInfo User={record.userId} />
                </div>
                <Button
                  className="relative  w-fit left-[64%] top-11  z-[1] text-white bg-red-500 "
                  onClick={() => HandledeleteJob(record._id)}
                >
                  Delete
                </Button>
              </div>
            </div>
          </Spin>
        </Spin>
      </Modal>
    </>
  );
};
export default AcceptButton;

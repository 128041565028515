import { React, useContext, useEffect, useState } from "react";
import { Table, Space, Button, Spin, Checkbox, Radio } from "antd";
import AcceptButton from "./AcceptButton";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { JobContext } from "../../Context/JobContext";
import TruncatedColumn from "./Orders/TruncatedColumn";
import EmptyOrder from "../../images/empty.png";
import { Link } from "react-router-dom";

const RecommendedJobs = () => {
  const { t } = useTranslation();
  const [recommended, setRecommended] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const { reviewJobs } = useContext(JobContext);
  console.log(reviewJobs, "review jobs");
  const uniqueTags = Array.from(
    new Set(
      recommended.flatMap((job) => job.tagAssociated.map((tag) => tag.name))
    )
  );

  recommended.sort((job1, job2) => {
    const date1 = new Date(job1.createdAt).toLocaleString();
    const date2 = new Date(job2.createdAt).toLocaleString();

    return new Date(date2) - new Date(date1);
  });
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);

    setTimeout(() => {
      setInitialLoading(false);
    }, 1500);
  }, []);
  const BaseUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    axios
      .get(`${BaseUrl}/jobs/recommended`, {
        withCredentials: true,
      })
      .then((res) => {
        const inReviewJobsIds = reviewJobs.map(
          (reviewJob) => reviewJob.jobId?._id
        );

        const filteredRecommended = res.data.recommendedJobs.filter(
          (job) => !inReviewJobsIds.includes(job._id)
        );

        setRecommended(filteredRecommended);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }, [reviewJobs]);

  const columns = [
    {
      key: "price",
      title: t("Price"),
      dataIndex: "price",
      sorter: (a, b) => a.price - b.price,
      render: (text, record) => (
        <span>
          ${record.price}
        </span>
      ),
      width: "15%",
    },
    {
      key: "title",
      title: t("Title"),
      dataIndex: "title",
      width: "15%",
    },
    {
      key: "description",
      title: t("Description"),
      dataIndex: "description",
      render: (text) => <TruncatedColumn text={text} />,
      width: "20%",
    },
    // {
    //   key: "time",
    //   title: t("Time Duration"),
    //   dataIndex: "hoursReqd",
    //   render: (text) => <span>{text} hours</span>,
    //   filters: [
    //     { text: "0-2 hours", value: "0-2" },
    //     { text: "2-4 hours", value: "2-4" },
    //     { text: "4-6 hours", value: "4-6" },
    //     { text: "6-8 hours", value: "6-8" },
    //   ],
    //   onFilter: (value, record) => {
    //     const [min, max] = value.split("-").map(Number);
    //     return record.hoursReqd >= min && record.hoursReqd <= max;
    //   },
    //   filterMultiple: false,
    //   filterDropdown: ({
    //     setSelectedKeys,
    //     selectedKeys,
    //     confirm,
    //     clearFilters,
    //   }) => (
    //     <div style={{ padding: 8 }} className="flex flex-col">
    //       <Radio.Group
    //         className="flex flex-col p-2"
    //         options={[
    //           { label: "0-2 hours", value: "0-2" },
    //           { label: "2-4 hours", value: "2-4" },
    //           { label: "4-6 hours", value: "4-6" },
    //           { label: "6-8 hours", value: "6-8" },
    //         ]}
    //         value={selectedKeys[0]}
    //         onChange={(e) => setSelectedKeys([e.target.value])}
    //         verticle={true}
    //       />
    //       <div style={{ marginTop: 8 }}>
    //         <Button
    //           onClick={() => confirm()}
    //           style={{ marginRight: 8 }}
    //           type="primary"
    //           className="text-white bg-blue-500"
    //         >
    //           OK
    //         </Button>
    //         <Button onClick={() => clearFilters()}>Reset</Button>
    //       </div>
    //     </div>
    //   ),
    // },

    {
      key: "area",
      title: t("Area"),
      dataIndex: "tagAssociated",
      render: (tagAssociated) => tagAssociated[0]?.name,
      filters: uniqueTags.map((area) => ({
        text: t(area),
        value: area,
      })),
      onFilter: (value, record) =>
        record.tagAssociated.some((tag) => tag.name === value),
      filterMultiple: true,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <>
          <div style={{ height: 120, overflowX: "auto", padding: "8px" }}>
            {" "}
            <Checkbox.Group
              className="flex flex-col "
              options={uniqueTags.map((area) => ({
                label: t(area),
                value: area,
              }))}
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e)}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "5px",
              margin: "5px",
            }}
          >
            <Button
              onClick={() => confirm()}
              style={{ marginRight: 8 }}
              type="primary"
              className="text-white bg-blue-500"
            >
              OK
            </Button>
            <Button onClick={() => clearFilters()}>Reset</Button>
          </div>
        </>
      ),
    },

    {
      key: "priority",
      title: t("Priority"),
      dataIndex: "priority",
      filters: [
        {
          text: t("HIGH"),
          value: "HIGH",
        },
        {
          text: t("MEDIUM"),
          value: "MEDIUM",
        },
        {
          text: t("LOW"),
          value: "LOW",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.priority.startsWith(value),
      width: "15%",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8, height: 150, overflow: "auto" }}>
          <div className="flex flex-col">
            <Checkbox.Group
              className="flex flex-col"
              options={[
                { label: t("HIGH"), value: "HIGH" },
                { label: t("MEDIUM"), value: "MEDIUM" },
                { label: t("LOW"), value: "LOW" },
              ]}
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e)}
              vertical
            />
          </div>

          <div style={{ marginTop: 8 }}>
            <Button
              onClick={() => confirm()}
              style={{ marginRight: 8 }}
              type="primary"
              className="text-white bg-blue-500"
            >
              OK
            </Button>
            <Button onClick={() => clearFilters()}>Reset</Button>
          </div>
        </div>
      ),
    },
    {
      title: t("Action"),
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <AcceptButton record={record} />
        </Space>
      ),
      width: "10%",
    },
  ];
  return (
    <div className="lg:space-y-4">
      {initialLoading ? (
        <div className=" min-h-[50vh] flex justify-center align-middle items-center">
          {" "}
          <Spin size="large" />
        </div>
      ) : recommended?.length === 0 ? (
        <div className="flex justify-center items-center">
          <div className="flex items-center justify-center flex-col">
            <img
              alt="empty"
              src={EmptyOrder}
              style={{
                height: "150px",
              }}
            />
            <p className="text-center text-xl">No Recommended Orders Found</p>
            <Link to="/app/professional/my-tags">
              <Button
                className="mt-10"
                type="primary"
                style={{ backgroundColor: "#1677ff" }}
              >
                Add Tags{" "}
              </Button>
            </Link>

            <p className="text-lg">To get more recommendation</p>
          </div>
        </div>
      ) : (
        <div className="overflow-scroll">
          <Table columns={columns} dataSource={recommended} bordered />
        </div>
      )}
    </div>
  );
};
export default RecommendedJobs;

import React, { useContext, useEffect, useState } from 'react';
import { Table, Button, Spin, Modal } from 'antd';
import { SupportContext } from '../../../../Context/AdminContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Completed = () => {
  const { allUsers,allJobsSupport,allTags,pendingLoading, freelancerData, 
    setFreelancerData,} = useContext(SupportContext);
  const [loading, setLoading] = useState(false); 
  const [selectedComplaint, setSelectedComplaint] = useState('');
  const [fullComplaintModalVisible, setFullComplaintModalVisible] = useState(false);
  const navigate = useNavigate();
  const getPendingClientRequest = () => {
    setLoading(true); 
    axios.get(`${process.env.REACT_APP_API_URL}/admin/complaint/freelancer/resolved`)
      .then(res => {
        setFreelancerData((prevData) => ({
          ...prevData,
          completed: res.data?.resolvedComplaints, 
        }));
        setLoading(false); 
      })
      .catch(err => {
        console.log(err);
        setLoading(false); 
      });
  };

  useEffect(() => {
    getPendingClientRequest(); 
  }, [pendingLoading]);

 
  const handleShowFullComplaint = (complaint) => {
    setSelectedComplaint(complaint);
    setFullComplaintModalVisible(true);
  };
  const columns = [
    {
      title: 'Freelancer Name',
      dataIndex: 'freelancerName',
      key: 'freelancerName',
    },
    {
      title: 'Client Name',
      dataIndex: 'clientName',
      key: 'clientName',
    },
    {
      title: 'Status',
      dataIndex: 'jobStatus',
      key: 'jobStatus',
    },
    {
      title: 'Complaint',
      dataIndex: 'complaint',
      key: 'Complaint',
      render: (complaint) => (
        <div>
          {complaint.length > 30 ? (
            <span>
              {complaint.substr(0, 30)}
              <Button type="link" onClick={() => handleShowFullComplaint(complaint)}>...More</Button>
            </span>
          ) : (
            <span>{complaint}</span>
          )}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Button type="primary" className="text-white bg-blue-500" onClick={() =>navigate(`/support/complaints/freelancer/completed/${record.key}`)}>Details</Button>
        </span>
      ),
    },
   
  ];

  const data = [...freelancerData?.completed]
  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
  .map(item => {
    const user = allUsers?.find(user => user?._id === item?.clientId);
    const freelancer = allUsers?.find(user => user?._id === item?.freelancerId);
    const price = allJobsSupport?.find(p => item?.jobId === p?._id);
    const area = allJobsSupport?.find(job => item?.jobId === job._id);
    const area1 = allTags.find(tag => area?.jobId.tagAssociated === tag?._id);
    return {
      key: item._id,
      clientName: user?.name,
      freelancerName: freelancer?.name,
      area: area1?.name,
      price: `$${price.price}`,
      jobStatus: item.status,
      complaint: item.complaint,
      createdAt: item.createdAt // Assuming createdAt is the timestamp property
    };
  });
 

  return (
    <>
      <Spin spinning={loading}> 
      <Table columns={columns} dataSource={data} />
    </Spin>
     <Modal
        title="Complaint"
        visible={fullComplaintModalVisible}
        onCancel={() => setFullComplaintModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setFullComplaintModalVisible(false)}>Close</Button>
        ]}
      >
        <p>{selectedComplaint}</p>
      </Modal>
    </>
  
  );
};

export default Completed;

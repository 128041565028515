import React, {useContext, useEffect} from "react";
import {Tabs} from "antd";
import {useTranslation} from "react-i18next";
import Withdraw from "./Withdraw";
import Completed from "../Orders/OrderData/Completed";
import {UserContext} from "../../../Context/UserContext";
import {Link} from "react-router-dom";
import axios from "axios";
import {JobContext} from "../../../Context/JobContext";
import BankDetails from "./BankDetails";

const Balance = () => {
    const {t} = useTranslation();
    const {User} = useContext(UserContext);
    const {
        setCompleteJobs,
    } = useContext(JobContext);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/jobs/status/completed`, {
                withCredentials: true,
            })
            .then((res) => {
                setCompleteJobs(res.data.completedJobs);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    if (User == null) {
        return <Link to="/login" className="cursor-pointer"><h1
            className="text-2xl  border-b-2 border-black w-fit hover:border-blue-400">Click here to login</h1>
        </Link>
    }

    return (
        <>
            <div className="space-y-4 overflow-scroll">
                <Tabs
                    tabPosition="top"
                    items={[
                        {
                            label: t("Withdraw"),
                            key: 1,
                            children: (
                                <div className="m-1 lg:m-7 space-y-4">
                                    <Tabs items={[
                                        {
                                            key: 1,
                                            label: 'Withdraw',
                                            children: <>
                                                <div className="bg-blue-500 rounded-md p-3">
                                                    <p className="text-white font-medium text-lg">
                                                        {t("Withdraw")}
                                                    </p>
                                                </div>
                                                <div>
                                                    <Withdraw/>{" "}
                                                </div>
                                            </>
                                        },
                                        {
                                            key: 2,
                                            label: 'Bank Details',
                                            children: <>
                                                <div className="bg-blue-500 rounded-md p-3">
                                                    <p className="text-white font-medium text-lg">
                                                        {t("Bank Details")}
                                                    </p>
                                                </div>
                                                <div>
                                                    <BankDetails/>{" "}
                                                </div>
                                            </>
                                        }
                                    ]}></Tabs>
                                </div>
                            ),
                        },

                        {
                            label: t("Completed Orders"),
                            key: 2,
                            children: (
                                <div className="m-1 lg:m-7 space-y-4">
                                    <div className="bg-green-500 rounded-md p-3">
                                        <p className="text-white font-medium text-lg">
                                            {t("Completed Orders")}
                                        </p>
                                    </div>
                                    <div>
                                        <Completed/>
                                    </div>
                                </div>
                            ),
                        },
                    ]}
                />
            </div>
        </>
    );
};

export default Balance;

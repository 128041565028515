import { createContext, useState } from "react";

export const UserContext = createContext();

const initialData = [
  {
    key: "1",
    name: "John Brown",
    email: "jhon3@gmail.com",
    rate: 43,
    projects: 46,
    tags: ["Graphics designer", "web developer"],
    earning: "29$",
  },
  {
    key: "2",
    name: "Jim Green",
    email: "jism32@gmail.com",
    rate: 70,
    projects: 16,
    tags: ["Architech"],
    earning: "29$",
  },
  {
    key: "3",
    name: "Joe Black",
    email: "joe907@gmail.com",
    rate: 43,
    projects: 16,
    tags: ["Backend developer"],
    earning: "29$",
  },
];
const orders = [
  {
    key: "1",
    orderId: "1523526",
    client: "Rocky",
    amount: 1600,
    freelancerName: "John Brown",
    serviceName: "Web Development",
    status: "completed",
  },
  {
    key: "2",
    orderId: "7267783",
    client: "Doe",
    amount: 160,
    freelancerName: "Jim Green",
    serviceName: "Graphic Design",
    status: "pending",
  },
  {
    key: "3",
    orderId: "8345738",
    client: "cena",
    amount: 900,
    freelancerName: "Joe Black",
    serviceName: "Graphic Design",
    status: "pending",
  },
];

const UserAuthContext = ({ children }) => {
  const [userList, setUserList] = useState([]);
  const [alluser, setAllusers] = useState(initialData);
  const [User, setUser] = useState(null);
  const[loading,setLoading] = useState(false)
  const [signupData, setSignupData] = useState({});
  const [userTag, setUserTag] = useState([]);
  const [fullprofile, setFullProfile] = useState(null);
  const [jobs,setJobs] = useState(null);

  const [selectedUser, setSelectedUser] = useState(null);

  const[freeload,setFreeLoad] = useState(false);
  return (
    <UserContext.Provider
      value={{
        alluser,
        setUserTag,
        jobs,setJobs,
        userTag,
        freeload,setFreeLoad,
        User,
        setUser,
        loading,
        setLoading,
        selectedUser,
        setSelectedUser,
        fullprofile,
        setFullProfile,
        orders,
        setAllusers,
        userList,
        setUserList,
        signupData,
        setSignupData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserAuthContext;

import { Button, Modal } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const TruncatedColumn = ({ text }) => {
    const { t } = useTranslation();
  
    const [isModalVisible, setIsModalVisible] = useState(false);
  
    const showModal = () => {
      setIsModalVisible(true);
    };
  
    const handleOk = () => {
      setIsModalVisible(false);
    };
  
    const handleCancel = () => {
      setIsModalVisible(false);
    };
  
    return (
      <>
        <div
          style={{
            maxWidth: 300,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {text}
        </div>
        {text.length > 50 && (
          <>
            <Button type="link" onClick={showModal} className="relative right-4">
              {t("See More")}
            </Button>
            <Modal
              title="Full Description"
              visible={isModalVisible}
              onOk={handleOk}
              okType="default"
              okText="Accept"
              cancelText="Close"
              onCancel={handleCancel}
            >
              <p>{text}</p>
            </Modal>
          </>
        )}
      </>
    );
  };

  export default TruncatedColumn;
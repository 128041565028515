import {createContext, useContext, useEffect, useMemo, useState} from "react";
import useWebSocket from "react-use-websocket";
import {UserContext} from "./UserContext";
import {NotificationContext} from "./NotificationContext";
import {SocketMessageTypes} from "../utils/constants";

export const SocketContext = createContext();


const SocketsContext = ({children}) => {
    const [newMessage, setNewMessage] = useState(null);

    const {User} = useContext(UserContext);
    const {fireNotification} = useContext(NotificationContext);

    const getSocketUrl = useMemo(() => {
        return new Promise((resolve) => {
            if (User) {
                resolve(`${process.env.REACT_APP_SOCKET_URL}`);
            }
        })
    }, [User])

    const {lastMessage, sendMessage} = useWebSocket(getSocketUrl, {
        share: true,
        shouldReconnect: () => true,
        reconnectInterval: 0,
        reconnectAttempts: 50,
        onOpen: () => {
            console.log('WebSocket connection established.');
        },
        onClose: () => {
            console.log('WebSocket connection disconnected.');
        },
        heartbeat: {
            message: JSON.stringify({type: 'HEARTBEAT', data: {message: "ping"}}),
            returnMessage: JSON.stringify({type: 'HEARTBEAT', data: {message: "pong"}}),
            timeout: 10 * 60 * 1000,
            interval: 25 * 1000
        }
    })

    useEffect(() => {
        if (lastMessage) {
            let messageData = JSON.parse(lastMessage.data.toString());
            if (messageData) {
                if (messageData.type === SocketMessageTypes.NEW_MESSAGE) {
                    fireNotification({
                        title: 'New Message',
                        data: {
                            body: messageData.data.message,
                            tag: 'new-message-' + messageData.data.createdAt
                        }
                    })
                }
            }
            setNewMessage(messageData);
        }
    }, [lastMessage])

    const sendSocketMessage = (message) => {
        sendMessage(JSON.stringify(message));
    }

    return (
        <SocketContext.Provider value={{newMessage, sendSocketMessage}}>
            {children}
        </SocketContext.Provider>
    );
};

export default SocketsContext;

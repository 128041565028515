import React, { useContext } from "react";
import { Table } from "antd";
import {  useLocation, useParams } from "react-router-dom";
import { SupportContext } from "../../../Context/AdminContext";

const ComplaintsDetails = () => {
  const { allUsers,allJobsSupport,allTags,clientData,freelancerData } = useContext(SupportContext);
  const { id } = useParams();
  const location = useLocation();

let data2 = [];

if (location.pathname.includes('/client/completed')) {
  if (clientData?.completed) {
    data2 = clientData.completed.map(item => {
      const user = allUsers?.find(user => user?._id === item?.clientId);
      const freelancer = allUsers?.find(user => user?._id === item?.freelancerId);
      const price = allJobsSupport?.find(p => item?.jobId === p?._id);
      const area = allJobsSupport?.find(job => item?.jobId === job._id);
      const area1 = allTags.find(tag => area?.jobId.tagAssociated === tag?._id);

      return {
        key: item._id,
        clientName: user?.name,
        freelancerName: freelancer?.name,
        area: area1?.name,
        price: `$${price?.price}`,
        jobStatus: item.status,
        complaint: item.complaint,
        priority: area?.jobId?.priority,
        description: price?.jobId?.description,
        clientBudget: `$${price?.jobId.minPrice} - $${price?.jobId?.maxPrice}`
      };
    });
  }
} else if (location.pathname.includes('/client/pending')) {
  if (clientData?.pendingReq) {
    data2 = clientData.pendingReq.map(item => {
      const user = allUsers?.find(user => user?._id === item?.clientId);
      const freelancer = allUsers?.find(user => user?._id === item?.freelancerId);
      const price = allJobsSupport?.find(p => item?.jobId === p?._id);
      const area = allJobsSupport?.find(job => item?.jobId === job._id);
      const area1 = allTags.find(tag => area?.jobId.tagAssociated === tag?._id);

      return {
        key: item._id,
        clientName: user?.name,
        freelancerName: freelancer?.name,
        area: area1?.name,
        price: `$${price?.price}`,
        jobStatus: item.status,
        complaint: item.complaint,
        priority: area?.jobId?.priority,
        description: price?.jobId?.description,
        clientBudget: `$${price?.jobId.minPrice} - $${price?.jobId?.maxPrice}`
      };
    });
  }
} else if (location.pathname.includes('/client/rejected')) {
  if (clientData?.rejected) {
    data2 = clientData.rejected.map(item => {
      const user = allUsers?.find(user => user?._id === item?.clientId);
      const freelancer = allUsers?.find(user => user?._id === item?.freelancerId);
      const price = allJobsSupport?.find(p => item?.jobId === p?._id);
      const area = allJobsSupport?.find(job => item?.jobId === job._id);
      const area1 = allTags?.find(tag => area?.jobId.tagAssociated === tag?._id);

      return {
        key: item._id,
        clientName: user?.name,
        freelancerName: freelancer?.name,
        area: area1?.name,
        price: `$${price?.price}`,
        jobStatus: item.status,
        complaint: item.complaint,
        priority: area?.jobId?.priority,
        description: price?.jobId?.description,
        clientBudget: `$${price?.jobId.minPrice} - $${price?.jobId?.maxPrice}`
      };
    });
  }
}

if (location.pathname.includes('/freelancer/completed')) {
  if (freelancerData?.completed) {
    data2 = freelancerData.completed.map(item => {
      const user = allUsers?.find(user => user?._id === item?.clientId);
      const freelancer = allUsers?.find(user => user?._id === item?.freelancerId);
      const price = allJobsSupport?.find(p => item?.jobId === p?._id);
      const area = allJobsSupport?.find(job => item?.jobId === job._id);
      const area1 = allTags.find(tag => area?.jobId.tagAssociated === tag?._id);

      return {
        key: item._id,
        clientName: user?.name,
        freelancerName: freelancer?.name,
        area: area1?.name,
        price: `$${price?.price}`,
        jobStatus: item.status,
        complaint: item.complaint,
        priority: area?.jobId?.priority,
        description: price?.jobId?.description,
        clientBudget: `$${price?.jobId.minPrice} - $${price?.jobId?.maxPrice}`
      };
    });
  }
} else if (location.pathname.includes('/freelancer/pending')) {
  if (freelancerData?.pendingReq) {
    data2 = freelancerData.pendingReq.map(item => {
      const user = allUsers?.find(user => user?._id === item?.clientId);
      const freelancer = allUsers?.find(user => user?._id === item?.freelancerId);
      const price = allJobsSupport?.find(p => item?.jobId === p?._id);
      const area = allJobsSupport?.find(job => item?.jobId === job._id);
      const area1 = allTags.find(tag => area?.jobId.tagAssociated === tag?._id);

      return {
        key: item._id,
        clientName: user?.name,
        freelancerName: freelancer?.name,
        area: area1?.name,
        price: `$${price?.price}`,
        jobStatus: item.status,
        complaint: item.complaint,
        priority: area?.jobId?.priority,
        description: price?.jobId?.description,
        clientBudget: `$${price?.jobId.minPrice} - $${price?.jobId?.maxPrice}`
      };
    });
  }
} else if (location.pathname.includes('/freelancer/rejected')) {
  if (freelancerData?.rejected) {
    data2 = freelancerData.rejected.map(item => {
      const user = allUsers?.find(user => user?._id === item?.clientId);
      const freelancer = allUsers?.find(user => user?._id === item?.freelancerId);
      const price = allJobsSupport?.find(p => item?.jobId === p?._id);
      const area = allJobsSupport?.find(job => item?.jobId === job._id);
      const area1 = allTags?.find(tag => area?.jobId.tagAssociated === tag?._id);

      return {
        key: item._id,
        clientName: user?.name,
        freelancerName: freelancer?.name,
        area: area1?.name,
        price: `$${price?.price}`,
        jobStatus: item.status,
        complaint: item.complaint,
        priority: area?.jobId?.priority,
        description: price?.jobId?.description,
        clientBudget: `$${price?.jobId.minPrice} - $${price?.jobId?.maxPrice}`
      };
    });
  }
}

 const finalArr = data2.filter(item=>item.key === id)

  const columns = [
    { title: "Field", dataIndex: "field" },
    { title: "Value", dataIndex: "value" },
  ];

  const data = [
    { key: "1", field: "Area", value: finalArr[0]?.area },
    { key: "2", field: "Description", value: finalArr[0]?.description },
    { key: "3", field: "Client", value:finalArr[0]?.clientName },
    { key: "4", field: "Freelancer", value:finalArr[0]?.freelancerName },
    { key: "5", field: "Complaint", value: finalArr[0]?.complaint },
    // { key: "6", field: "Freelancer Budget", value: finalArr[0]?.price},
    { key: "6", field: "Price", value:finalArr[0]?.price },
    { key: "7", field: "Priority", value:finalArr[0]?.priority },
   
  ];

  return (
    <>  
    <div className=" lg:mx-4 lg:p-6 my-5 mx-2 rounded-xl p-2 md:my-4 md:mx-2 md:p-4">
      <div className="flex justify-center items-center">
        <div className="p-4 bg-slate-200 text-gray-900 lg:w-[60%] border rounded-md shadow-md">
          <h2 className="text-lg font-semibold text-center mb-3">Order Detail</h2>
          <Table columns={columns} dataSource={data} pagination={false} />
        </div>
      </div>
    </div>
    </>

  );
};

export default ComplaintsDetails;

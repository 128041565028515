export const SocketMessageTypes = {
    NEW_MESSAGE: 'NEW_MESSAGE',
    LOAD_MESSAGE: 'LOAD_MESSAGE',
    HEARTBEAT: 'HEARTBEAT',
    MARK_AS_READ: 'MARK_AS_READ',
}

export const PayoutStatus = {
    IN_PROGRESS: 'In Progress',
    COMPLETED: 'Completed',
    IN_QUEUE: 'In queue',
    DECLINED: 'Declined',
    PENDING: 'Pending',
    ERROR: 'Error',
}
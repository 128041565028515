import React, { useContext, useEffect } from "react";
import { Tabs } from "antd";
import Active from "./OrderData/Active";
import Completed from "./OrderData/Completed";
import { useTranslation } from "react-i18next";
import ReviewJobs from "./OrderData/ReviewJobs";
import axios from "axios";
import { JobContext } from "../../../Context/JobContext";
import RejectedJobs from "./OrderData/RejectedJobs";
import TabPane from "antd/es/tabs/TabPane";

const Orders = () => {
  const { t } = useTranslation();
  const {
    setActiveJobs,
    setRejectedJobs,
    setCompleteJobs,
    activeKey,
    setReviewJobs,
    setActiveKey,
  } = useContext(JobContext);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/jobs/status/active`, {
        withCredentials: true,
      })
      .then((res) => {
        setActiveJobs(res.data.activeJobs);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/jobs/status/in-review`, {
        withCredentials: true,
      })
      .then((res) => {
        setReviewJobs(res.data.reviewJobs);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/jobs/status/rejected`, {
        withCredentials: true,
      })
      .then((res) => {
        setRejectedJobs(res.data.rejectedJobs);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/jobs/status/completed`, {
        withCredentials: true,
      })
      .then((res) => {
        setCompleteJobs(res.data.completedJobs);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className="space-y-4 overflow-scroll">
        <Tabs tabPosition="top" activeKey={activeKey} onChange={setActiveKey}>
          <TabPane tab={t("Active Order")} key="1">
            <div className="m-1 lg:m-7 space-y-4">
              <div className="bg-blue-500 rounded-md p-3">
                <p className="text-white font-medium text-lg">
                  {t("Active Order")}
                </p>
              </div>
              <div>
                <Active />
              </div>
            </div>
          </TabPane>
          <TabPane tab={t("Review Order")} key="2">
            <div className="m-1 lg:m-7 space-y-4">
              <div className="bg-yellow-500 rounded-md p-3">
                <p className="text-white font-medium text-lg">
                  {t("Review Order")}
                </p>
              </div>
              <div>
                <ReviewJobs />
              </div>
            </div>
          </TabPane>
          <TabPane tab={t("Completed Order")} key="3">
            <div className="m-1 lg:m-7 space-y-4">
              <div className="bg-green-500 rounded-md p-3">
                <p className="text-white font-medium text-lg">
                  {t("Completed Order")}
                </p>
              </div>
              <div>
                <Completed />
              </div>
            </div>
          </TabPane>
          <TabPane tab={t("Rejected Order")} key="4">
            <div className="m-1 lg:m-7 space-y-4">
              <div className="bg-red-500 rounded-md p-3">
                <p className="text-white font-medium text-lg">
                  {t("Rejected Order")}
                </p>
              </div>
              <div>
                <RejectedJobs />
              </div>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};
export default Orders;

import { createContext, useState } from "react";

export const SupportContext = createContext();

const AdminContext = ({ children }) => {
  const [allTags, setAllTags] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [allJobsSupport, setAllJobsSupport] = useState([]);
  const [complaintloading, setComplaintLoading] = useState(false);
  const [pendingLoading, setPendingLoading] = useState(false);
  const [clientData, setClientData] = useState({
    pendingReq: [],
    completed: [],
    rejected: [],
  });
  const [freelancerData, setFreelancerData] = useState({
    pendingReq: [],
    completed: [],
    rejected: [],
  });
  return (
    <SupportContext.Provider
      value={{
        allTags,
        setAllTags,
        allUsers,
        setAllUsers,
        clientData,
        freelancerData, 
        setFreelancerData,
        setClientData,
        allJobsSupport,
        setAllJobsSupport,
        complaintloading,
        setComplaintLoading,
        pendingLoading,
        setPendingLoading,
      }}
    >
      {children}
    </SupportContext.Provider>
  );
};

export default AdminContext;

import React, { useContext, useEffect, useState } from "react";
import { Spin, Tabs } from "antd";
import Completed from "./OrderData/Completed";
import Requests from "./OrderData/Requests";
import Posted from "./OrderData/Posted";
import { useTranslation } from "react-i18next";
import { ClientContext } from "../../../Context/ClientContext";
import axios from "axios";
import NotStarted from "./OrderData/NotStarted";

const Orders = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const {
    setPostedJobs,
    setPendingJobs,
    setUserTags,
    setCompleteJobs,
    deletjobloader,
    postedJobs,
    setNotStartedJobs,
    loadingjobs,
  } = useContext(ClientContext);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/client/all`, {
        withCredentials: true,
      })
      .then((res) => {
        setLoading(false);
        setPostedJobs(res.data.postedJobs);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [loadingjobs]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/client/in-review`, {
        withCredentials: true,
      })
      .then((res) => {
        setPendingJobs(res.data.pendingRequests);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [loadingjobs, deletjobloader]);

  useEffect(() => {
    const userProfile = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/tags`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });
        const data = await response.json();
        if (data.success) {
          setUserTags(data.tags);
        } else {
          console.log("Error: ", data.msg);
        }
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    };

    userProfile();
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/client/not-started`, {
        withCredentials: true,
      })
      .then((res) => {
        setNotStartedJobs(res.data.job);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [loadingjobs]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/client/completed`, {
        withCredentials: true,
      })
      .then((res) => {
        setCompleteJobs(res.data.completedJobs);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className="space-y-4 ">
        <Spin spinning={loading}>
          <Tabs
            tabPosition="top"
            items={[
              {
                label: t("Orders Posted"),
                key: 1,
                children: (
                  <div className="m-1 lg:m-7 space-y-4 overflow-scroll">
                    <div className="bg-blue-500 rounded-md p-3">
                      <p className="text-white font-medium text-lg">
                        Orders Posted
                      </p>
                    </div>
                    <div>
                      <Posted />
                    </div>
                  </div>
                ),
              },
              {
                label: t("Pending Requests"),
                key: 2,
                children: (
                  <div className="m-1 lg:m-7 space-y-4">
                    <div className="bg-yellow-500 rounded-md p-3">
                      <p className="text-white font-medium text-lg">
                        {t("Pending Requests ")}
                      </p>
                    </div>
                    <div>
                      {" "}
                      <Requests />{" "}
                    </div>
                  </div>
                ),
              },
              {
                label: t("Not Yet Started Orders"),
                key: 3,
                children: (
                  <div className="m-1 lg:m-7 space-y-4">
                    <div className="bg-blue-300 rounded-md p-3">
                      <p className="text-white font-medium text-lg">
                        {t("Not Yet Started Orders")}
                      </p>
                    </div>
                    <div>
                      {" "}
                      <NotStarted />
                    </div>
                  </div>
                ),
              },
              {
                label: t("Completed Orders"),
                key: 4,
                children: (
                  <div className="m-1 lg:m-7 space-y-4">
                    <div className="bg-green-500 rounded-md p-3">
                      <p className="text-white font-medium text-lg">
                        {t("Completed Orders")}
                      </p>
                    </div>
                    <div>
                      {" "}
                      <Completed />{" "}
                    </div>
                  </div>
                ),
              },
            ]}
          />
        </Spin>
      </div>
    </>
  );
};
export default Orders;
